import { useEffect, useState } from 'react'

export const useIsMobile = (breakpoint = 768) => {
  const [isMobile, setIsMobile] = useState(false)

  const getIsMobile = () => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < breakpoint)
    }
  }

  useEffect(() => {
    getIsMobile()

    window.addEventListener('resize', getIsMobile)

    return () => {
      window.removeEventListener('resize', getIsMobile)
    }
  }, [])

  return isMobile
}
