import { IFeatureFlag } from '@/types'
import { getFeatureFlags } from 'api/featureFlags'
import { useQuery } from 'react-query'

type FeatureFlags = {
  [key in IFeatureFlag['attributes']['name']]?: boolean
}

export const useFeatureFlags = () => {
  const { data: featureFlags } = useQuery(
    'featureFlags',
    async () => {
      const flags = await getFeatureFlags()
      if (!flags.data) return {} as FeatureFlags
      return flags.data.reduce((acc, flag) => {
        return {
          ...acc,
          [flag.attributes.name]: flag.attributes.state,
        }
      }, {} as FeatureFlags)
    },
    { refetchOnMount: true, refetchOnWindowFocus: false, initialData: {} }
  )

  return { featureFlags: featureFlags as FeatureFlags }
}
