import { EmblaCarouselType } from 'embla-carousel'
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react'

type State = {
  selectedIndex: number
  scrollSnaps: number[]
  onPaginate: (index: number) => void
}

export const EmblaPaginationContext = createContext<State>({} as State)

export const EmblaPaginationProvider = ({
  emblaApi,
  children,
}: {
  emblaApi: EmblaCarouselType | undefined
  children: ReactNode
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

  const onPaginate = useCallback(
    (index: number) => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
    },
    [emblaApi]
  )

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onInit(emblaApi)
    onSelect(emblaApi)

    emblaApi.on('reInit', onInit)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onInit, onSelect])

  return (
    <EmblaPaginationContext.Provider
      value={{ selectedIndex, onPaginate, scrollSnaps }}
    >
      {children}
    </EmblaPaginationContext.Provider>
  )
}
