import { useEffect } from 'react'

const setStyles = (element: HTMLElement, disable: boolean) => {
  if (!element) return

  const scrollY = window.scrollY

  if (disable) {
    element.style.overflowY = 'hidden'
    // element.style.position = 'fixed'
    // element.style.top = `${-scrollY}px`
  }

  if (!disable) {
    const top = Number(element.style.top.replace('px', ''))
    element.style.overflowY = ''
    // element.style.position = ''
    // element.style.top = ''

    // if (top && !isNaN(top)) {
    //   window.scrollTo(0, (top || 0) * -1)
    // }
  }
}

type Props = {
  element?: HTMLElement
  disable?: boolean
}

export const useDisableScroll = ({ element, disable = true }: Props) => {
  useEffect(() => {
    if (!element) {
      setStyles(document.body, disable)
      return
    }

    setStyles(element, disable)
  }, [disable, element])
}
