import { Release } from 'api/releases'
import { ReleaseCardProps } from 'components/release-calendar/ReleaseCard'
import { PropsWithChildren, createContext } from 'react'

/**
 * MappedReleases is an object which holds an array of releases for every existing date
 * Eg:
 * {
 *    '2023-11-29': [{}, {}, {}],
 *    '2023-10-04': [{}, {}],
 *    '2023-11-08': [{}, {}, {}, {}]
 * }
 */
export type MappedReleases = Record<string, ReleaseCardProps[]>

type State = {
  initialPastReleases?: Release[]
}

type Props = State & PropsWithChildren

export const ReleaseCalendarContext = createContext<State>({} as State)

export const ReleaseCalendarProvider = ({
  children,
  initialPastReleases,
}: Props) => {
  return (
    <ReleaseCalendarContext.Provider
      value={{
        initialPastReleases,
      }}
    >
      {children}
    </ReleaseCalendarContext.Provider>
  )
}
