import { MouseEventHandler } from 'react'
import { create } from 'zustand'

type Action = {
  name: string
  secondName?: string
  onClick: MouseEventHandler
}

type State = {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  isDisabled: boolean
  setIsDisabled: (isDisabled: boolean) => void
  action: Action
  setAction: (action: Action) => void
  hasDocuments: boolean
  setHasDocuments: (hasDocuments: boolean) => void
}

export const useTradeAction = create<State>((set, get) => {
  return {
    isLoading: false,
    setIsLoading: isLoading => {
      if (isLoading === get()?.isLoading) return

      set(() => ({ isLoading }))
    },
    isDisabled: false,
    setIsDisabled: isDisabled => {
      if (isDisabled === get()?.isDisabled) return

      set(() => ({ isDisabled }))
    },
    action: {
      name: 'Next',
      secondName: '',
      onClick: () => console.log('Action clicked'),
    },
    setAction: action => {
      if (action === get()?.action) return

      set(() => ({ action }))
    },
    hasDocuments: false,
    setHasDocuments: hasDocuments => {
      if (hasDocuments === get()?.hasDocuments) return

      set(() => ({ hasDocuments }))
    },
  }
})
