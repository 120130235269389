import classNames from 'classnames'
import { Button } from 'components/common/Button'
import { Icon } from 'components/common/Icon'
import { Separator } from 'components/common/Separator'
import { useDisableScroll } from 'hooks/useDisableScroll'
import { useRouter } from 'next/router'
import { lazy, MouseEvent, Suspense, useEffect, useState } from 'react'
import { useOverlay } from '../../contexts'
import { tw } from '../../utils/strings'

const InstantSearch = lazy(() =>
  import('components/common/InstantSearch').then(m => ({
    default: m.InstantSearch,
  }))
)

/**
 * You should use the `OverlayLazy` component.
 */
export const Overlay = () => {
  const {
    isOpen,
    setIsOpen,
    config: {
      heading,
      body,
      hasIcon,
      className,
      bodyClassName,
      headingClassName,
      closeable,
      onClose,
      withInstantSearch,
      preventScrollDisable,
    },
  } = useOverlay()
  const router = useRouter()

  const [shouldMountContent, setShouldMountContent] = useState(false) // remove content after animation finishes

  const shouldDisplayContent = isOpen || shouldMountContent

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    // instantly display the content
    if (isOpen) {
      setShouldMountContent(true)
    }

    // set a timeout to remove the content after the animation finishes
    if (!isOpen) {
      timeout = setTimeout(() => {
        setShouldMountContent(false)
      }, 500)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isOpen])

  useEffect(() => {
    // close overlay on route change
    const handleChangeStart = () => {
      setIsOpen(false)
      setShouldMountContent(false)
    }

    router.events.on('routeChangeStart', handleChangeStart)

    return () => {
      router.events.off('routeChangeStart', handleChangeStart)
    }
  }, [router.events])

  useDisableScroll({
    disable: !preventScrollDisable && isOpen,
  })

  const close = (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (event.target === event.currentTarget && closeable !== false) {
      setIsOpen(false)
      onClose?.()
    }
  }

  const content = (
    <>
      {heading && (
        <>
          <header className='flex h-16 w-full justify-center bg-cool-50 lg:h-20'>
            <div className='container flex w-full justify-center'>
              <div
                className={classNames(
                  'flex w-full items-center justify-between md:max-w-[theme(screens.sm)]',
                  headingClassName
                )}
              >
                <div className='flex-1'>{heading}</div>
                <Button pill style='text' onClick={() => setIsOpen(false)}>
                  <Icon
                    name='close'
                    size={24}
                    className='text-base text-cool-700'
                  />
                </Button>
              </div>
            </div>
          </header>
          <Separator color='cool-300' className='lg:hidden' />
        </>
      )}

      {hasIcon && (
        <Icon
          name='close'
          className='absolute right-5.5 top-5.5 z-[9999] cursor-pointer text-xl text-cool-150 duration-200 ease-in-out hover:scale-110 md:text-3xl'
          onClick={() => setIsOpen(false)}
        />
      )}

      <div
        className={classNames(
          'fixed flex w-full justify-center bg-cool-900 bg-opacity-60',
          heading
            ? tw`h-[calc(100%_-_theme(spacing.16))] lg:h-[calc(100%_-_theme(spacing.20))]`
            : 'h-full',
          className
        )}
        onClick={event => close(event)}
      >
        <div
          className={classNames(
            'flex max-h-full w-full justify-center md:py-4',
            bodyClassName
          )}
          onClick={event => close(event)}
        >
          {body}
        </div>
      </div>
    </>
  )

  return (
    <div
      id='overlay-container'
      className={classNames(
        'overlay fixed top-0 h-screen w-full transition-[height]',
        isOpen ? 'overlay-open' : 'overlay-closed'
      )}
    >
      {shouldDisplayContent && (
        <>
          {withInstantSearch ? (
            <Suspense fallback={null}>
              <InstantSearch>{content}</InstantSearch>
            </Suspense>
          ) : (
            content
          )}
        </>
      )}
    </div>
  )
}
