import { Separator } from 'components/common/Separator'
import { useDisableScroll } from 'hooks/useDisableScroll'
import { useDrawer } from '../../contexts'
import classNames from 'classnames'

export const Drawer = () => {
  const {
    isOpen,
    setIsOpen,
    config: {
      direction,
      heading,
      body,
      className,
      overlayClassName,
      headingClassName,
      hasSeparator,
    },
  } = useDrawer()

  useDisableScroll({
    disable: isOpen,
  })

  const openDirection = `${direction}-0`

  return (
    <>
      <div
        style={{
          pointerEvents: isOpen ? 'all' : 'none',
        }}
        className={classNames(
          'drawer__backdrop fixed top-0 z-[9999] h-full max-h-screen w-full justify-center bg-cool-900 bg-opacity-60',
          isOpen ? 'drawer__backdrop-open' : 'drawer__backdrop-closed',
          overlayClassName
        )}
        onClick={e => e.target === e.currentTarget && setIsOpen(false)}
      />

      <div
        id='drawer-container'
        className={classNames(
          'drawer__container fixed top-0 z-[99999] flex h-full w-full max-w-[350px] flex-col pb-[env(safe-area-inset-bottom)] will-change-transform',
          `drawer__container-${direction}-${isOpen ? 'open' : 'closed'}`,
          openDirection,
          className
        )}
      >
        <div className='min-h-fit'>
          <header
            className={`flex h-full min-h-[theme(spacing.16)] w-full justify-center border-b border-solid border-b-cool-400 bg-cool-50 lg:min-h-[theme(spacing.20)] ${headingClassName}`}
          >
            <div className='flex min-h-full w-full items-start justify-between px-6'>
              <div className='relative flex min-h-full w-full flex-col justify-center py-4'>
                {heading}
              </div>
            </div>
          </header>
        </div>

        {hasSeparator && <Separator />}

        <aside className='h-[calc(100%-(theme(spacing.16)*2))] flex-1'>
          {body}
        </aside>
      </div>
    </>
  )
}
