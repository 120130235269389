type Props = {
  color?:
    | 'cool-100'
    | 'cool-200'
    | 'cool-300'
    | 'cool-400'
    | 'cool-500'
    | 'cool-600'
    | 'cool-700'
    | 'cool-800'
    | 'cool-900'
  children?: React.ReactNode
  className?: string
}

export const Separator = ({
  color = 'cool-400',
  children,
  className,
}: Props) => {
  if (children) {
    return (
      <div
        className={`flex w-full items-center justify-between space-y-7.5 ${className}`}
      >
        <span className={`h-px w-full bg-${color}`}></span>
        {children}
        <span className={`h-px w-full bg-${color}`}></span>
      </div>
    )
  }

  return <div className={`h-px w-full bg-${color} ${className}`}></div>
}
