export const expiryValues = {
  30: '30',
  60: '60',
  untilAccepted: '0',
}

export const expiryOptions = [
  { value: expiryValues[30], label: '30_days' },
  { value: expiryValues[60], label: '60_days' },
  { value: expiryValues.untilAccepted, label: 'until_accepted' },
]
