import { create } from 'zustand'

type VisibilityState = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

type OverlayConfig = {
  hasIcon?: boolean
  heading?: React.ReactNode
  body?: React.ReactNode
  className?: string
  bodyClassName?: string
  headingClassName?: string
  closeable?: boolean
  onClose?: () => void
  scrollOnClose?: boolean
  withInstantSearch?: boolean
  preventScrollDisable?: boolean
}

type OverlayState = {
  config: OverlayConfig
  setConfig: (config: OverlayConfig) => void
} & VisibilityState

type AuthState =
  | 'login'
  | 'register'
  | 'email-confirmation'
  | 'email-verification'
  | 'forgot-password'
  | 'reset-password'
  | undefined

type AuthModalConfig = {
  closeable?: boolean
  onClose?: () => void
  socialLoginRedirectQuery?: Record<string, unknown>
}

type AuthModalState = {
  state: AuthState
  setState: (state: AuthState) => void
  config: AuthModalConfig
  setConfig: (config: AuthModalConfig) => void
} & VisibilityState

type State = {
  overlay: OverlayState
  authModal: AuthModalState
}

const initialConfig: AuthModalConfig = { closeable: true }

const useOverlayStore = create<State>((set, get) => {
  const isOpen = get()?.overlay?.isOpen || false
  const setIsOpen = (isOpen: boolean) => {
    set(state => ({
      overlay: { ...state.overlay, isOpen },
      authModal: { ...state.authModal, isOpen },
    }))
  }

  const overlay: OverlayState = {
    isOpen,
    setIsOpen,
    config: {
      hasIcon: false,
    },
    setConfig: config => {
      set(state => ({
        overlay: {
          ...state.overlay,
          config: { ...initialConfig, ...config },
        },
      }))
    },
  }

  const authModal: AuthModalState = {
    isOpen,
    setIsOpen,
    state: undefined,
    setState: authState => {
      set(state => ({ authModal: { ...state.authModal, state: authState } }))
    },
    config: { ...initialConfig },
    setConfig: config => {
      set(state => ({
        authModal: {
          ...state.authModal,
          config,
        },
      }))
    },
  }

  return {
    overlay,
    authModal,
  }
})

export const useOverlay = () => {
  const { overlay } = useOverlayStore()

  return overlay
}

export const useAuthModal = () => {
  const { authModal } = useOverlayStore()

  return authModal
}
