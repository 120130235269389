import { Dayjs } from 'dayjs'

const Sunday = 0
const Saturday = 6

export const addBusinessDays = (originalDate: Dayjs, numDaysToAdd: number) => {
  let daysRemaining = numDaysToAdd

  const newDate = originalDate.clone()

  if (numDaysToAdd === 0) return newDate

  if (numDaysToAdd > 0) {
    while (daysRemaining > 0) {
      newDate.add(1, 'days')
      if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
        daysRemaining--
      }
    }
  } else {
    while (daysRemaining < 0) {
      newDate.subtract(1, 'days')
      if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
        daysRemaining++
      }
    }
  }

  return newDate
}

export const getBusinessDaysDiff = (start: Dayjs, end: Dayjs) => {
  let numWorkDays = 0
  let currentDate = start.clone()
  if (currentDate.isBefore(end)) {
    while (currentDate <= end) {
      // Skips Sunday and Saturday
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        numWorkDays++
      }
      currentDate = currentDate.add(1, 'day')
    }
  } else {
    while (currentDate > end) {
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        numWorkDays++
      }
      currentDate = currentDate.subtract(1, 'day')
    }
  }
  return numWorkDays
}

export const formatLocalDate = (date: Dayjs, separator = '-') => {
  if (!date?.isValid?.()) {
    return 'Until accepted'
  }

  return date.format(`DD${separator}MM${separator}YYYY`)
}
