import { create } from 'zustand'

type LoadingState = {
  isLoading: boolean
  isArtworkPage: boolean
  isArtistPage: boolean
  isReleasePage: boolean
}

type SetState = (cb: (prevState: LoadingState) => LoadingState) => void

type State = {
  state: LoadingState
  setState: SetState
  resetState(): void
  setStateHandler(partialState: Partial<LoadingState>): void
  isLogoLoaded: boolean
  setIsLogoLoaded(isLogoLoaded: boolean): void
  isIntercomLoaded: boolean
  setIsIntercomLoaded(isIntercomLoaded: boolean): void
}

const initialLoadingState: LoadingState = {
  isLoading: false,
  isArtworkPage: false,
  isArtistPage: false,
  isReleasePage: false,
}

export const useLoading = create<State>(set => ({
  state: { ...initialLoadingState },
  setState: cb =>
    set(prev => {
      const newState = cb(prev.state)
      return { state: newState }
    }),
  resetState: () => set(() => ({ state: { ...initialLoadingState } })),
  setStateHandler: partialState =>
    set(prev => ({ state: { ...prev.state, ...partialState } })),
  isLogoLoaded: false,
  setIsLogoLoaded: isLogoLoaded => set(() => ({ isLogoLoaded })),
  isIntercomLoaded: false,
  setIsIntercomLoaded: isIntercomLoaded => set(() => ({ isIntercomLoaded })),
}))
