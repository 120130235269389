import routes from 'constants/routes'
import { useAuthModal, useOverlay } from 'contexts'
import { useRouter } from 'next/router'
import { Suspense, lazy, useEffect } from 'react'

const AuthModal = lazy(() =>
  import('components/common/AuthModal').then(m => ({ default: m.AuthModal }))
)

export function AuthModalObserver() {
  const { isOpen, state, config, setState, setConfig, setIsOpen } =
    useAuthModal()
  const { setConfig: setOverlayConfig } = useOverlay()
  const router = useRouter()

  useEffect(() => {
    if (!isOpen) {
      setState(undefined)
      setConfig({ closeable: true })
      config.onClose?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (!state) {
      setOverlayConfig({})
      return
    }

    setOverlayConfig({
      className: 'lg:flex sm:items-center',
      body: (
        <Suspense>
          <AuthModal />
        </Suspense>
      ),
      closeable: config.closeable,
    })
  }, [state, setOverlayConfig, config])

  useEffect(() => {
    if (accountExists()) {
      setState('login')
      setIsOpen(true)

      return
    }

    if (shouldDisplaySignUp()) {
      setState('register')
      setIsOpen(true)
      router.replace({ pathname: router.pathname, query: {} })

      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  const accountExists = () => {
    const fairartUserExists = router.query.fairartUserExists === 'true'
    const fairartUserExistsNotGoogle =
      router.query.fairartUserExistsNotGoogle === 'true'
    const fairartUserExistsNotApple =
      router.query.fairartUserExistsNotApple === 'true'

    if (
      fairartUserExists ||
      fairartUserExistsNotApple ||
      fairartUserExistsNotGoogle
    ) {
      return true
    }

    return false
  }

  const shouldDisplaySignUp = () => {
    if (router.pathname !== routes.homepage) return false

    const { action } = router.query

    if (action !== 'sign-up') return false

    return true
  }

  return null
}
