export type Err = {
  name: string
  message: string
  status: number
  details?: {
    err?: string
  }
}

const apiDomain = process.env.NEXT_PUBLIC_API_DOMAIN || 'cms.preview.fairart.io'
// const apiDomain = 'http://localhost:1337'

// export const API_BASEPATH = `https://${apiDomain}`
export const API_BASEPATH = apiDomain.startsWith('http')
  ? apiDomain
  : `https://${apiDomain}`
