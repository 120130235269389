import {
  CollectionType,
  IAddress,
  IAditionalCertification,
  IArtist,
  IArtwork,
  IOrder,
} from '@/types'
import type { AddressElementProps } from '@stripe/react-stripe-js'
import { OrderPaymentStatus, OrderPayoutStatus } from 'api/order'
import { UserProfile } from 'api/profiles'
import { ArtistCard } from 'components/artists/ArtistCard'
import { ArtistSummary } from 'components/artists/ArtistSummary'
import { ArtworkCard } from 'components/artwork/ArtworkCard'
import { ArtworkDetails } from 'components/artwork/ArtworksDetails'
import dayjs from 'dayjs'
import { ComponentProps } from 'react'
import { ArtworkRoyalty } from 'types'
import { getArtworkNameWithEdition } from 'utils/strings'
import { formatLocalDate } from './dates'
import {
  ARTWORK_IMG_PLACEHOLDER,
  ImageExtendedFormat,
  Media,
  getArtistImageUrl,
  getImageUrl,
} from './image'
import { sortArtworkImages } from './sort'
import { getArtistUrl, getArtworkUrl } from './urls'

export const mapIArtistToArtistSummary = (artist: IArtist) => {
  const mappedArtist = mapIArtistToArtist(artist)

  if (!mappedArtist) return

  return {
    ...mappedArtist,
    birthYear: mappedArtist?.birthYear?.toString(),
  } as ComponentProps<typeof ArtistSummary>
}

type Nationality = {
  name?: string
  artists?: { data?: IArtist[] }
  numCode?: string
  alpha2Code?: string
  alpha3Code?: string
  shortName?: string
}

export type Artist = {
  id: number
  name: string
  nationalities: Nationality[]
  nationality?: Nationality
  avatar?: Media
  profile?: Media
  birthYear?: number
  href: string
  description?: string
  socialLinks: {
    instagram?: string
    website?: string
    email?: string
  }
  dateOfBirth?: string
}

export const mapIArtistToArtist = (artist: IArtist) => {
  try {
    const { id, attributes } = artist || {}
    const { name, dateOfBirth, images, nationalities, socialMedia } =
      attributes || {}

    const avatar = images?.data?.find(
      image =>
        image.attributes.name?.includes('(1)') ||
        image.attributes.name?.includes('1')
    )

    const profile = images?.data?.find(
      image =>
        image.attributes.name?.includes('(2)') ||
        image.attributes.name?.includes('2')
    )

    const description = attributes.description || null
    const { instagram, website, contact: email } = socialMedia || {}
    const socialLinks = {
      instagram: instagram || null,
      website: website || null,
      email: email || null,
    }

    if (!name) return

    return {
      id,
      name,
      avatar: avatar ? { id: avatar.id, ...avatar.attributes } : {},
      profile: profile ? { id: profile.id, ...profile.attributes } : {},
      birthYear: dateOfBirth
        ? parseInt(dayjs(dateOfBirth).format('YYYY'))
        : null,
      birthDate: dateOfBirth ? dayjs(dateOfBirth).format('YYYY-MM-DD') : null,
      nationalities:
        nationalities?.data?.map(({ attributes }) => ({
          name: attributes.name,
          countryCode: attributes.alpha2Code,
        })) || [],
      href: getArtistUrl(name, id),
      description,
      socialLinks,
      dateOfBirth: dateOfBirth || null,
    } as Artist
  } catch (err) {
    console.error(err)

    return undefined
  }
}

export const mapIArtistsToArtists = (artists?: IArtist[]) => {
  if (!artists) return []
  return artists.flatMap(artist => mapIArtistToArtist(artist) || [])
}

export const mapIArtistToArtistCard = (
  artist: IArtist,
  imageFormat: ImageExtendedFormat = 'small'
) => {
  const mappedArtist = mapIArtistToArtist(artist)
  if (!mappedArtist) return
  const { name, avatar, nationality, birthYear, href } = mappedArtist

  return {
    src: getArtistImageUrl(name, avatar, imageFormat),
    name,
    nationality: nationality?.alpha2Code || null,
    birthDate: birthYear?.toString() || null,
    href,
    additionalSrc: {
      medium: getArtistImageUrl(name, avatar, 'medium'),
    },
  } as ComponentProps<typeof ArtistCard>
}

export const mapIArtistsToArtistCards = (
  artists?: IArtist[],
  imageFormat: ImageExtendedFormat = 'small'
) => {
  if (!artists) return []
  return artists.flatMap(
    artist => mapIArtistToArtistCard(artist, imageFormat) || []
  )
}

export type Artwork = {
  id: number
  name: string
  // imageUrl: string | null
  artists: Artist[]
  editions: Edition[]
  images: Media[]
  href?: string
  royalty?: ArtworkRoyalty
}

export const mapIArtworkToArtworkCard = (
  artwork: IArtwork,
  imageFormat: ImageExtendedFormat = 'thumbnail'
): ComponentProps<typeof ArtworkCard> | undefined => {
  const { id, attributes } = artwork || {}
  const { name, images, artists: initialArtists, editions } = attributes || {}

  const image =
    images?.data?.length &&
    images?.data?.sort((img1, img2) => sortArtworkImages(img1, img2))?.[0]

  const artists =
    initialArtists?.data?.map(artist => ({
      id: artist.id,
      name: artist.attributes.name || '',
    })) || null
  const artistName = artists?.[0]?.name || ''

  // const editionWithValues = editions?.data?.find(
  //   edition =>
  //     edition.attributes.lowestAsk?.data?.attributes.price ||
  //     edition.attributes.highestBid?.data?.attributes.price
  // )
  const mainEdition =
    editions?.data?.find(edition => edition.attributes?.name === 'Main') || null

  const alternativeEdition =
    editions?.data?.find(
      edition =>
        edition.attributes.name !== 'Main' &&
        edition.attributes.lowestAsk?.data?.attributes?.price
    ) || null

  const edition = (() => {
    const alternativeEdtionLo =
      alternativeEdition?.attributes?.lowestAsk?.data?.attributes?.price || 0
    const mainEdtionLo =
      mainEdition?.attributes?.lowestAsk?.data?.attributes?.price || 0

    if (
      // query.showRelevantEdition !== undefined &&
      alternativeEdition &&
      mainEdition &&
      ((alternativeEdition?.attributes.lowestAsk?.data &&
        !mainEdition?.attributes.lowestAsk?.data) ||
        alternativeEdtionLo < mainEdtionLo)
    )
      return alternativeEdition
    // if (editionWithValues) return editionWithValues

    return mainEdition
  })()

  const lowestAsk =
    edition?.attributes.lowestAsk?.data?.attributes.price || null
  const highestBid =
    edition?.attributes.highestBid?.data?.attributes.price || null

  if (!name || !artists || !edition) {
    return
  }

  const getSrc = (format: ImageExtendedFormat) => {
    if (!image) return ARTWORK_IMG_PLACEHOLDER
    return getImageUrl(
      { id: image.id, ...image.attributes },
      format,
      ARTWORK_IMG_PLACEHOLDER
    )
  }

  return {
    id,
    src: getSrc(imageFormat),
    artworkName: getArtworkNameWithEdition(name, edition.attributes?.name),
    artists,
    artistName,
    buyPrice: lowestAsk || null,
    sellPrice: highestBid || null,
    href: getArtworkUrl(artists, name, id, edition.attributes?.name),
    medium: artwork.attributes?.medium || null,
    releaseDate: artwork.attributes?.releaseDate || null,
    editionName: edition.attributes?.name || null,
    additionalSrc: {
      medium: getSrc('medium'),
    },
  }
}

export const mapIArtworkToArtwork = (artwork: IArtwork) => {
  const { id, attributes } = artwork || {}
  const { name, images, artists: initialArtists } = attributes || {}

  const editions = mapIArtworkToEditions(artwork)
  const artists = mapIArtistsToArtists(initialArtists?.data)

  if (!name || !artists) return

  return {
    id,
    name,
    artists,
    editions,
    images: images?.data?.map(img => img?.attributes) ?? [],
    href: getArtworkUrl(artists, name, id),
    royalty: attributes.royalty,
  } as Artwork
}

export const mapIArtworksToArtworks = (artworks: IArtwork[]) => {
  if (!artworks) return []
  return artworks.flatMap(artwork => mapIArtworkToArtwork(artwork) || [])
}

export type ArtworkByName = {
  artists: { name: string; id: number }[]
  artworkName: string
  files: { url: string }[]
  id: number
}

export const mapArtworkByQueryToArtworkCard = ({
  artworkName,
  artists,
  files,
  id,
}: ArtworkByName): ComponentProps<typeof ArtworkCard> => {
  return {
    artworkName,
    id,
    href: getArtworkUrl(artists, artworkName, id),
    src: files?.[0]?.url || ARTWORK_IMG_PLACEHOLDER,
    artists,
    artistName: artists?.[0]?.name || ' ',
  }
}

export const mapArtworksByQueryToArtworkCard = (artworks: ArtworkByName[]) => {
  return artworks.map(artwork => mapArtworkByQueryToArtworkCard(artwork))
}

export type Ask = {
  quantity: number
  price: number
}

export type Bid = {
  quantity: number
  price: number
}

export type ShipmentCategory = 'S' | 'M' | 'L' | 'XL' | 'XXL'

export type Edition = {
  id: number
  name: string
  shipmentCategory: ShipmentCategory
  artworkDetails: ComponentProps<typeof ArtworkDetails>
  additionalCertification: Omit<IAditionalCertification, 'id'>
  asks: Ask[]
  bids: Bid[]
  lowestAsk: number
  lowestAskId: number
  highestBid: number
  highestBidId: number
}

export const mapIArtworkToEditions = (artwork: IArtwork) => {
  const { attributes, id } = artwork || {}
  const {
    editions: artworkEditions,
    gallery: artworkGallery,
    medium: artworkMedium,
    releaseDate,
    isFullDate,
    royalty,
    upcomingRelease,
  } = attributes || {}

  const editions =
    artworkEditions?.data?.map(edition => {
      const date = releaseDate
        ? isFullDate
          ? formatLocalDate(dayjs(releaseDate))
          : parseInt(dayjs(releaseDate).format('YYYY'))
        : null
      const size = edition.attributes.size || null
      const medium = artworkMedium || null
      const material = edition.attributes.material || null
      const editionSize = edition.attributes.editionSize
        ? edition.attributes.editionSize
        : null
      const price = edition.attributes.mainPrice || null
      const latestSale = 0 // TODO: Update this once the backend supply it
      const currency = edition.attributes.currency || null
      const gallery = artworkGallery?.data?.attributes.name || null
      const features = edition.attributes.features || null
      const information = edition.attributes.information || null
      const includes = edition.attributes.includes || null
      const shipmentCategory = edition.attributes.shipmentCategory || null
      const additionalCertification =
        edition.attributes.additionalCertification || {}

      const pricesToPriceQuantities = (prices: number[]) => {
        const unique = [
          ...new Set(
            prices.map(price => JSON.stringify({ quantity: 0, price }))
          ),
        ].map(ask => JSON.parse(ask))

        prices.forEach(price => {
          unique.forEach(ask => {
            if (ask.price === price) {
              ask.quantity += 1
            }
          })
        })

        return unique as { quantity: number; price: number }[]
      }

      const approvedAsks =
        edition.attributes.asks?.data
          ?.filter(ask => ask.attributes.status === 'Approved')
          ?.sort((ask1, ask2) => {
            if (!ask1.attributes.price) return -1
            if (!ask2.attributes.price) return 1

            return ask1.attributes.price - ask2.attributes.price
          }) || []
      const asksPrices =
        approvedAsks.map(ask => ask.attributes.price || 0) || []

      const approvedBids =
        edition.attributes.bids?.data
          ?.filter(bid => bid.attributes.status === 'Approved')
          ?.sort((bid1, bid2) => {
            if (!bid1.attributes.price) return 1
            if (!bid2.attributes.price) return -1

            return bid2.attributes.price - bid1.attributes.price
          }) || []
      const bidsPrices =
        approvedBids.map(bid => bid.attributes.price || 0) || []

      const asks: Ask[] = pricesToPriceQuantities(asksPrices)
      const bids: Bid[] = pricesToPriceQuantities(bidsPrices)

      const lowestAsk =
        edition.attributes.lowestAsk?.data?.attributes.price || null
      const highestBid =
        edition.attributes.highestBid?.data?.attributes.price || null
      const highestBidId = edition.attributes.highestBid?.data?.id || null
      const lowestAskId = edition.attributes.lowestAsk?.data?.id || null

      return {
        id: edition.id,
        name: edition.attributes.name || '',
        artworkDetails: {
          id,
          isFullDate,
          date,
          size,
          medium,
          material,
          editionSize,
          price,
          latestSale,
          currency,
          gallery,
          features,
          includes,
          information,
          royalty,
          upcomingRelease,
        },
        shipmentCategory,
        additionalCertification,
        asks,
        bids,
        lowestAsk,
        lowestAskId,
        highestBid,
        highestBidId,
      } as Edition
    }) || []

  return editions
}

export const mapIArtworksToArtworkCards = (
  artworks: IArtwork[],
  imageFormat: ImageExtendedFormat = 'thumbnail'
) => {
  if (!artworks) return []
  return artworks.flatMap(
    artwork => mapIArtworkToArtworkCard(artwork, imageFormat) || []
  )
}

export type Fees = {
  applicationFee: number
  dhlInsuranceFee: number
  processFee: number
  royalitiesFee: number
}

export type Order = {
  id: number
  createdAt: string
  payoutStatus: OrderPayoutStatus
  paymentStatus: OrderPaymentStatus
  orderPaidAt: string
  price: number
  payoutDoneAt?: string
  payoutDate?: string
  seller?: { id: number }
  buyer?: { id: number }
  shippingPrice?: number
} & Fees

export const mapIOrderToOrder = (order: IOrder) => {
  const { id, attributes } = order || {}
  const {
    createdAt,
    paymentStatus,
    price,
    applicationFee,
    dhlInsuranceFee,
    processFee,
    royalitiesFee,
    orderPaidAt,
    payoutDoneAt,
    payoutStatus,
    payoutDate,
  } = attributes || {}
  return {
    id,
    createdAt,
    price,
    paymentStatus,
    applicationFee,
    dhlInsuranceFee,
    processFee,
    royalitiesFee,
    orderPaidAt,
    payoutStatus,
    payoutDoneAt,
    payoutDate,
  } as Order
}

export const flatCollectionType = <T extends CollectionType>(
  collectionType: T
) => {
  return {
    id: collectionType.id,
    ...collectionType.attributes,
  } as { id: number } & T['attributes']
}

type StripeValues = NonNullable<
  NonNullable<AddressElementProps['options']>['defaultValues']
>
export const getStripeValuesFromUserAddress = (
  address?: IAddress,
  phone?: string
): {
  address: NonNullable<StripeValues['address']>
  name: NonNullable<StripeValues['name']>
  phone: NonNullable<StripeValues['phone']>
} => {
  return {
    address: {
      country:
        address?.country && address.country.length === 2
          ? address.country
          : 'GB',
      city: address?.city || '',
      line1: address?.address || '',
      line2: address?.address_2 || '',
      postal_code: address?.postalCode || '',
      state: address?.state || '',
    },
    name: address?.name || '',
    phone: phone || '',
  }
}

export const getUserAddressFromStripeValues = (
  address?: StripeValues['address'],
  name?: StripeValues['name']
): Partial<IAddress> => {
  if (!address)
    return {
      address: '',
      address_2: '',
      country: '',
      city: '',
      name: '',
      postalCode: '',
      state: '',
    }

  return {
    address: address?.line1 || '',
    address_2: address?.line2 || '',
    city: address?.city || '',
    country: address?.country || '',
    name: name || '',
    postalCode: address?.postal_code,
    state: address?.state,
  }
}

export function getUserTimezone(userProfile?: UserProfile) {
  return userProfile?.releaseSettings?.timeZone || dayjs.tz.guess()
}
