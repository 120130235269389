import {
  getComingReleases,
  getFutureReleases,
  getPastReleases,
  getReleasesNotifications,
} from 'api/releases'
import { useAuth } from 'contexts/hooks/useAuth'
import {
  MappedReleases,
  ReleaseCalendarContext,
} from 'contexts/providers/ReleaseCalendarProvider'
import { useUserProfile } from 'hooks'
import qs from 'qs'
import { useContext, useMemo } from 'react'
import { useInfiniteQuery, useQuery } from 'react-query'
import { flatCollectionType, getUserTimezone } from 'utils/data'
import { groupReleases } from 'utils/release'

const initialPagination = { page: 1, pageCount: 30 }

export const useReleaseCalendar = () => useContext(ReleaseCalendarContext)

function useTimezone() {
  const { user, isLoading: isLoadingAuth } = useAuth()
  const { data: userProfile } = useUserProfile()

  const timezone = useMemo(() => {
    if (isLoadingAuth || (Boolean(user) && !userProfile)) return undefined

    return getUserTimezone(userProfile)
  }, [isLoadingAuth, user, userProfile])

  return timezone
}

export function usePastReleases(
  config: Parameters<typeof getPastReleases>[0] = {
    pagination: initialPagination,
  }
) {
  const timezone = useTimezone()
  const { initialPastReleases = [] } = useReleaseCalendar()

  const { data = { list: [], mapped: {} }, ...rest } = useQuery(
    [
      'releases',
      'past',
      timezone,
      config.pagination?.page,
      config.pagination?.pageSize,
    ],
    async () => {
      const { data: rawPastReleases = [] } = await getPastReleases(config)
      const pastReleases = rawPastReleases.map(flatCollectionType)
      const mappedPastReleases = groupReleases(pastReleases, timezone!)

      const filterPastRelease = pastReleases.sort((release1, release2) => {
        const r1TimedEdition = !!release1?.releaseDate?.timedEdition
        const r2TimedEdition = !!release2?.releaseDate?.timedEdition
        return r1TimedEdition === r2TimedEdition ? 0 : r1TimedEdition ? -1 : 1
      })

      return { list: filterPastRelease, mapped: mappedPastReleases }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: Boolean(timezone),
      initialData: { list: initialPastReleases, mapped: {} },
    }
  )

  return { ...rest, data }
}

export function useInfinitePastReleases() {
  const timezone = useTimezone()

  const { data, ...rest } = useInfiniteQuery(
    ['releases', 'past', 'infinite', timezone],
    async ({ pageParam = 1 }) => {
      const {
        data: rawPastReleases = [],
        meta: { pagination },
      } = await getPastReleases({
        pagination: { page: pageParam, pageSize: 30 },
      })

      const pastReleases = rawPastReleases.map(flatCollectionType)
      const mappedPastReleases = groupReleases(pastReleases, timezone!)

      return { list: pastReleases, mapped: mappedPastReleases, pagination }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      getNextPageParam: ({ pagination }) => {
        if (pagination.page < pagination.pageCount) {
          return pagination.page + 1
        }
        return undefined
      },
      enabled: Boolean(timezone),
    }
  )

  const totalItems = useMemo(() => {
    if (!data?.pages) return 0

    return data.pages.reduce(
      (prevValue, currPage) => prevValue + currPage.list.length,
      0
    )
  }, [data])

  return { ...rest, data: { totalItems, ...data } }
}

export function useFutureReleases(
  config: Parameters<typeof getFutureReleases>[0] = {
    pagination: initialPagination,
  }
) {
  const timezone = useTimezone()

  const { data = { list: [], mapped: {} }, ...rest } = useQuery(
    [
      'releases',
      'future',
      timezone,
      config.pagination?.page,
      config.pagination?.pageSize,
    ],
    async () => {
      const { data: rawFutureReleases = [] } = await getFutureReleases(config)
      const futureReleases = rawFutureReleases.map(flatCollectionType)
      const mappedFutureReleases = groupReleases(futureReleases, timezone!)

      return { list: futureReleases, mapped: mappedFutureReleases }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: Boolean(timezone),
    }
  )

  return { ...rest, data }
}

export function useComingReleases(
  config: Parameters<typeof getComingReleases>[0] = {
    pagination: initialPagination,
  }
) {
  const { data = { list: [], mapped: {} }, ...rest } = useQuery(
    [
      'releases',
      'coming',
      config.pagination?.page,
      config.pagination?.pageSize,
    ],
    async () => {
      const { data: rawComingReleases = [] } = await getComingReleases(config)
      const comingReleases = rawComingReleases.map(flatCollectionType)
      const mappedComingReleases: MappedReleases = {
        soon: comingReleases,
      }

      return { list: comingReleases, mapped: mappedComingReleases }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  )

  return { ...rest, data }
}

export function usePastSavedReleases() {
  const { user } = useAuth()
  const { data: savedIds = [] } = useSavedReleasesIds()

  const { data = { list: [] }, ...rest } = useQuery(
    ['releases', 'saved', 'past', savedIds],
    async () => {
      if (!savedIds?.length) return { list: [] }

      const { data: rawPastReleases } = await getPastReleases({
        pagination: { pageSize: 20 },
        filters: { id: { $in: savedIds } },
      })
      const pastReleases = rawPastReleases.map(flatCollectionType)

      return { list: pastReleases }
    },
    { enabled: Boolean(user && savedIds?.length) }
  )

  return { ...rest, data }
}

export function useFutureSavedReleases() {
  const { user } = useAuth()
  const timezone = useTimezone()
  const { data: savedIds = [] } = useSavedReleasesIds()

  const { data = { list: [], mapped: {} }, ...rest } = useQuery(
    ['releases', 'saved', 'future', savedIds, timezone],
    async () => {
      if (!timezone || !savedIds.length) return { list: [], mapped: {} }

      const { data: rawFutureReleases } = await getFutureReleases({
        pagination: { pageSize: 100 },
        filters: { id: { $in: savedIds } },
      })
      const futureReleases = rawFutureReleases.map(flatCollectionType)
      const mappedFutureReleases = groupReleases(futureReleases, timezone)

      return { list: futureReleases, mapped: mappedFutureReleases }
    },
    {
      enabled: Boolean(user && timezone && savedIds.length),
    }
  )

  return { ...rest, data }
}

export function useSavedReleasesIds() {
  const { user } = useAuth()

  function buildQuery(page: number) {
    return qs.stringify(
      {
        pagination: { page, pageSize: 100 },
        fields: ['id'],
        populate: { release: { fields: ['id'] } },
        filters: {
          user: { id: user?.id },
        },
      },
      { encodeValuesOnly: true }
    )
  }

  return useQuery(
    ['releases', 'saved', 'ids', user],
    async () => {
      const ids = []

      let page = 1
      const res = await getReleasesNotifications(buildQuery(page))
      const { meta, data } = res

      if (!data) return []

      ids.push(
        ...data.map(notif => notif.attributes.release?.data?.id).filter(Boolean)
      )

      if (meta?.pagination) {
        while (page < meta.pagination.pageCount) {
          page += 1

          const { data } = await getReleasesNotifications(buildQuery(page))

          if (data) {
            ids.push(
              ...data
                .map(notif => notif.attributes.release?.data?.id)
                .filter(Boolean)
            )
          }
        }
      }

      return ids
    },
    { enabled: Boolean(user) }
  )
}
