import { IReleaseDate } from '@/types/IRelease'
import { Release } from 'api/releases'
import { MappedReleases } from 'contexts/providers/ReleaseCalendarProvider'
import dayjs from 'dayjs'

/**
 * @Daniel
 * Used to get the difference in minutes between two timezones
 * Eg:
 * tz1 = UTC - 60m
 * tz2 = UTC + 180m
 * => offset = 60 + 180 = 240m
 * Hope there won't be the need to be touched in the future
 */
const getOffsetBetweenTimezones = (tz1: string, tz2: string) => {
  const releaseUTCOffset = dayjs().tz(tz1).utcOffset()
  const localUTCOffset = dayjs().tz(tz2).utcOffset()
  let releaseToLocalOffset = 0

  if (releaseUTCOffset === 0) {
    releaseToLocalOffset = localUTCOffset
  } else if (localUTCOffset === 0) {
    releaseToLocalOffset = -1 * releaseUTCOffset
  } else if (releaseUTCOffset > 0 && localUTCOffset > 0) {
    if (releaseUTCOffset < localUTCOffset)
      releaseToLocalOffset = localUTCOffset - releaseUTCOffset
    else releaseToLocalOffset = localUTCOffset - releaseUTCOffset
  } else if (releaseUTCOffset < 0 && localUTCOffset < 0) {
    if (releaseUTCOffset < localUTCOffset)
      releaseToLocalOffset = releaseUTCOffset + Math.abs(localUTCOffset)
    else releaseToLocalOffset = releaseUTCOffset + localUTCOffset
  } else if (releaseUTCOffset < 0 && localUTCOffset > 0) {
    releaseToLocalOffset = Math.abs(releaseUTCOffset) + localUTCOffset
  } else if (releaseUTCOffset > 0 && localUTCOffset < 0) {
    releaseToLocalOffset = -1 * (releaseUTCOffset + Math.abs(localUTCOffset))
  }

  return releaseToLocalOffset
}

const combineDate = (d: string, t?: string) => `${d}T${t || '00:00:00'}.000Z`

export const getExactReleaseDate = (
  releaseDate: IReleaseDate,
  timezone: string,
  raffle?: boolean
) => {
  if (!releaseDate) return { releaseStart: null, releaseEnd: null }

  const {
    date,
    dateStart,
    dateEnd,
    timedEdition,
    time,
    timeStart,
    timeEnd,
    dateOnly,
    dateOnlyTimezone,
  } = releaseDate

  if (date && dateOnly && dateOnlyTimezone && timezone) {
    const releaseToLocalOffset = getOffsetBetweenTimezones(
      dateOnlyTimezone,
      timezone
    )
    let releaseStartDate = null

    if (releaseToLocalOffset) {
      releaseStartDate = dayjs(combineDate(date, time)).tz(timezone)

      if (releaseToLocalOffset < 0) {
        releaseStartDate = releaseStartDate.subtract(
          Math.abs(releaseToLocalOffset),
          'minutes'
        )
      } else {
        releaseStartDate = releaseStartDate.add(
          Math.abs(releaseToLocalOffset),
          'minutes'
        )
      }
    }

    if (releaseStartDate) {
      return {
        releaseStart: releaseStartDate.toISOString(),
        releaseEnd: null,
      }
    }
  }

  if (dateStart && dateEnd && (timedEdition || raffle))
    return {
      releaseStart: combineDate(dateStart, timeStart),
      releaseEnd: combineDate(dateEnd, timeEnd),
    }

  if (date) return { releaseStart: combineDate(date, time), releaseEnd: null }

  return { releaseStart: null, releaseEnd: null }
}

/**
 * Groups releases based on their release date.
 *
 * Eg:
 * `{
 *    '2023-11-29': [{}, {}, {}],
 *    '2023-10-04': [{}, {}],
 *    '2023-11-08': [{}, {}, {}, {}]
 * }`
 */
export function groupReleases(releases: Release[], timezone: string) {
  const mappedReleases: MappedReleases = {}

  releases.forEach(release => {
    if (!release.releaseDate) return

    const { releaseStart } = getExactReleaseDate(
      release.releaseDate,
      timezone,
      release.raffle
    )

    if (!releaseStart) {
      console.log('releaseStart is missing', release.name)
      return
    }

    const formattedStartDate = dayjs(releaseStart)
      .tz(timezone)
      .format('YYYY-MM-DD')

    if (!mappedReleases[formattedStartDate]) {
      mappedReleases[formattedStartDate] = []
    }

    mappedReleases[formattedStartDate].push(release)
  })

  return mappedReleases
}
