import { getArtists } from 'api/artists'
import { getArtworks } from 'api/artworks'
import { UserAsk } from 'api/asks'
import { getReleases } from 'api/releases'
import { detect } from 'detect-browser'
import qs from 'qs'
import { AnchorHTMLAttributes } from 'react'
import slugify from 'slugify'
import { NamedEntity } from 'types'
import { chunkArray } from 'utils/arrays'
import { artworksSorts } from 'utils/sort'
import { titleCase } from 'utils/strings'

export type ArtistBreadcrumb = {
  name: string
  id: number
}

export const getArtistsFromUrl = (url: string) => {
  const breadcrumbs = url.split('/')

  if (breadcrumbs.includes('artists')) {
    const artist: ArtistBreadcrumb = {
      name: titleCase(
        breadcrumbs[breadcrumbs.length - 2]?.replaceAll('-', ' ') || ''
      ),
      id: Number(breadcrumbs[breadcrumbs.length - 1]),
    }
    return [artist]
  }

  if (!breadcrumbs.includes('artworks')) return []

  const start = breadcrumbs.indexOf('artworks') + 1
  const end = -2
  const artists: ArtistBreadcrumb[] = []
  chunkArray(breadcrumbs.slice(start, end)).forEach(artist =>
    artists.push({ name: artist[0], id: Number(artist[1]) })
  )
  return artists
}

export const getArticleUrl = (
  slug: string,
  category: string | null,
  id: number
) => {
  if (!category) return `/editorial/${slug}/${id}`

  return `/editorial/${slugify(category, {
    replacement: '-',
    lower: true,
  })}/${slugify(slug, {
    replacement: '-',
    lower: true,
  })}/${id}`
}

export const getCategoryUrl = (categoryName: string) =>
  `/editorial/${slugify(categoryName, {
    replacement: '-',
    lower: true,
  })}`

export const getArtistUrl = (name: string, id: number) =>
  `/artists/${slugify(name, {
    replacement: '-',
    lower: true,
  })}/${id}`

export const getArtworkUrl = (
  artists: { id: number; name: string }[],
  name: string,
  id: number,
  editionName?: string
) => {
  let url = getArtworkUrlFromRoot('artworks', artists, name, id)

  if (editionName && !['undefined', 'Main'].includes(editionName)) {
    url += `?edition=${editionName}`
  }

  return url
}

export const getArtworkUrlFromRoot = (
  root: string,
  artists: { id: number; name: string }[],
  name: string,
  id: number
) =>
  `/${root}/${artists
    .map(
      ({ id, name }) =>
        slugify(name, {
          replacement: '-',
          lower: true,
        }) +
        '/' +
        id
    )
    .join('/')}/` +
  `${slugify(name, {
    replacement: '-',
    lower: true,
  })}/${id}`

export const createRedirect = async (
  href: string,
  target: AnchorHTMLAttributes<HTMLAnchorElement>['target'] = '_blank'
) => {
  const browserName = detect()?.name || ''

  if (
    typeof window !== 'undefined' &&
    ['safari', 'ios'].includes(browserName)
  ) {
    window.location.href = href
    return
  }
  // NOTE: This approach over the window.location.href approach is better since it
  // guarantees that the redirect will be in a new tab.
  const a = document.createElement('a')
  a.href = href
  a.target = target
  a.rel = 'noreferrer noopener'
  a.click()
}

export type PathsFnConfig = {
  startFromPage?: number
  limit?: number
}

export const getArtworksPaths = async ({
  startFromPage = 1,
  limit,
}: PathsFnConfig = {}) => {
  const getQuery = (page: number) =>
    qs.stringify(
      {
        fields: ['name'],
        populate: {
          artists: {
            fields: ['name'],
          },
        },
        pagination: { page, pageSize: limit && limit > 100 ? 100 : limit },
        sort: artworksSorts.recommended,
      },
      { encodeValuesOnly: true }
    )

  let {
    data: artworks,
    meta: {
      // eslint-disable-next-line prefer-const
      pagination: { page, pageCount },
    },
  } = await getArtworks(getQuery(startFromPage))

  while (page < pageCount) {
    if (limit && artworks.length >= limit) break

    page++

    const { data } = await getArtworks(getQuery(page))

    artworks = [...artworks, ...data]
  }

  const paths = artworks
    .map(artwork => {
      const {
        id,
        attributes: { name, artists },
      } = artwork

      if (!id || !name || !artists?.data?.length)
        return { params: { slug: undefined } }

      const url = getArtworkUrl(
        artists.data.map(artist => ({
          name: artist.attributes.name ?? '',
          id: artist.id,
        })) ?? '',
        name,
        id
      )

      if (!url) return { params: { slug: undefined } }

      const slug = url
        .replace(/\/\//g, '/')
        .replace('/artworks/', '')
        .split('/')

      return { params: { slug } }
    })
    .filter(path => Boolean(path?.params?.slug))

  return paths
}

export const getArtistsPaths = async ({
  startFromPage = 1,
  limit,
}: PathsFnConfig = {}) => {
  const getQuery = (page: number) =>
    qs.stringify(
      {
        fields: ['name'],
        pagination: { page, pageSize: limit && limit > 100 ? 100 : limit },
      },
      { encodeValuesOnly: true }
    )

  let {
    data: artists,
    meta: {
      // eslint-disable-next-line prefer-const
      pagination: { page, pageCount },
    },
  } = await getArtists(getQuery(startFromPage))

  while (page < pageCount) {
    if (limit && artists.length >= limit) break

    page++

    const { data } = await getArtists(getQuery(page))

    artists = [...artists, ...data]
  }

  const paths = artists
    .map(artist => {
      const { id, attributes } = artist

      if (!id || !attributes.name) return { params: { slug: undefined } }

      const url = getArtistUrl(attributes.name, id)

      const slug = url.replace(/\/\//g, '/').replace('/artists/', '').split('/')

      return { params: { slug } }
    })
    .filter(path => Boolean(path?.params?.slug))

  return paths
}

export const getReleasesPaths = async ({
  startFromPage = 1,
  limit,
}: PathsFnConfig = {}) => {
  const getQuery = (page: number) =>
    qs.stringify(
      {
        fields: ['name'],
        populate: {
          artists: {
            fields: ['name'],
          },
        },
        pagination: { page, pageSize: limit && limit > 100 ? 100 : limit },
      },
      { encodeValuesOnly: true }
    )

  let {
    data: releases,
    meta: {
      // eslint-disable-next-line prefer-const
      pagination: { page, pageCount },
    },
  } = await getReleases(getQuery(startFromPage))

  while (page < pageCount) {
    if (limit && releases.length >= limit) break

    page++

    const { data } = await getReleases(getQuery(page))

    releases = [...releases, ...data]
  }

  const paths = releases
    .map(release => {
      const {
        id,
        attributes: { name, artists },
      } = release

      if (!id || !name) return { params: { slug: undefined } }

      const url = getReleaseUrl(
        id,
        name,
        artists?.data?.map(artist => ({
          id: artist.id,
          name: artist.attributes.name || '',
        })) || []
      )

      const slug = url
        .replace(/\/\//g, '/')
        .replace('/release-calendar/', '')
        .split('-')

      return { params: { slug } }
    })
    .filter(path => Boolean(path?.params?.slug))

  return paths
}

export const getSubmitDocsUrlFromUserAsk = (ask: UserAsk) => {
  if (!ask?.edition?.artwork) return '#'

  const { artists, id, name } = ask.edition.artwork

  if (!artists || !id || !name) return '#'

  return (
    getArtworkUrlFromRoot('ask-or-sell/submit-documents', artists, name, id) +
    `/Main?askId=${ask.id}`
  )
}

export const getReleaseUrl = (
  id: number,
  name: string,
  artists: NamedEntity[],
  extraArtists?: string
) => {
  const artistNames = []

  artists.forEach(({ name }) =>
    artistNames.push(slugify(name, { lower: true, replacement: '-' }))
  )

  if (extraArtists) {
    artistNames.push(slugify(extraArtists, { lower: true, replacement: '-' }))
  }

  const urlParts = [
    artistNames.join('-'),
    slugify(name, { lower: true, replacement: '-' }),
    id,
  ]

  return `/release-calendar/${urlParts.join('-')}`
}

export const getOrderUrl = (
  artworkId: number,
  askId: number,
  bidId: number,
  orderId: number,
  trackingNumber?: string | number
) => {
  const query = [
    `trackingNumber=${trackingNumber}`,
    `askId=${askId}`,
    `bidId=${bidId}`,
    `orderId=${orderId}`,
  ]

  return `/my-account/buying/order/${artworkId}/?${query.join('&')}`
}

export const getSaleUrl = (
  artworkId: number,
  askId: number,
  bidId: number,
  orderId: number,
  trackingNumber?: string | number
) => {
  const query = [
    `trackingNumber=${trackingNumber}`,
    `askId=${askId}`,
    `bidId=${bidId}`,
    `orderId=${orderId}`,
  ]

  return `/my-account/selling/sale/${artworkId}/?${query.join('&')}`
}

export const getEditBidUrl = (
  artists: NamedEntity[],
  name: string,
  artworkId: number
) => {
  return (
    getArtworkUrlFromRoot('bid-or-buy', artists, name, artworkId) +
    '/Main?action=edit'
  )
}

export const getEditAskUrl = (
  artists: NamedEntity[],
  name: string,
  artworkId: number
) => {
  return (
    getArtworkUrlFromRoot('ask-or-sell', artists, name, artworkId) +
    '/Main?action=edit'
  )
}

export const getSellNowUrl = (
  artists: NamedEntity[],
  name: string,
  artworkId: number,
  dhlInsurance?: boolean
) => {
  return (
    getArtworkUrlFromRoot('ask-or-sell', artists, name, artworkId) +
    `/Main?DHLInsurance=${dhlInsurance}&action=sell`
  )
}

export const getBuyNowUrl = (
  artists: NamedEntity[],
  name: string,
  artworkId: number
) => {
  return (
    getArtworkUrlFromRoot('bid-or-buy', artists, name, artworkId) +
    '/Main?action=buy'
  )
}
