import { IAsk, IIncludes } from '@/types'
import { UserOrder } from 'api/order'
import {
  Ask,
  AskStatus,
  DocumentStatus,
  NamedEntity,
  SellingTradeType,
} from 'types'
import { Media } from 'utils/image'
import { API_BASEPATH, Err } from '.'

export const askErrors = {
  bidSold: 'This bid has already been sold',
  noBidsFound: 'No bids found',
}

type CreateInitialAskResponse = {
  askId?: number
  error?: Err
}

type AddAskDocumentResponse = {
  files?: { id: number; name: string }[]
  error?: Err
}

type DeleteAskDocumentResponse = {
  ok?: boolean
  error?: Err
}

type AddAskDocumentsCommentsResponse = {
  ok?: boolean
  error?: Err
}

type PlaceAskResponse = {
  askStatus?: AskStatus
  orderId?: number
  error?: Err
}

type SellNowResponse = {
  askId?: number
  liveAskId?: number
  error?: Err
}

export type UserAsk = {
  id?: number
  price?: number
  expiresAt?: string
  status?: AskStatus
  documentStatus?: DocumentStatus
  documentDeadline?: string
  createdAt?: string
  updatedAt?: string
  bid?: { id?: number }
  highestBid?: any
  lowestAsk?: any
  edition?: {
    artwork?: {
      id?: number
      artists?: { id: number; name: string }[]
      name?: string
      images?: Media[]
    }
  }
  orders?: UserOrder[]
}

type GetUserAsksResponse = {
  data?: UserAsk[]
  maxPage?: number
  error?: Err
}

type GetAskResponse = {
  data?: IAsk
  error?: Err
}

type GetAsksResponse = {
  data?: IAsk[]
  error?: Err
}

type EditAskResponse = {
  data?: IAsk
  error?: Err
}

export type BulkAskEdition = {
  id: number
  name: string
  includes?: IIncludes
  highestBid?: { id?: number; price?: number } | null
  lowestAsk?: { id?: number; price?: number } | null
}

type BulkAsksResponse = (Ask & {
  edition: {
    id: number
    artwork: {
      id: number
      name: string
      artists: NamedEntity[]
      editions: BulkAskEdition[]
      images: { id: number; url: string }[]
    }
  }
})[]

type DeleteAskResponse = {
  error?: Err
  data?: unknown
}

export const createInitialAsk = async (
  editionId: number,
  price: number,
  expiresInDays: number,
  isBulk = false
) => {
  const response = await fetch(`${API_BASEPATH}/api/asks`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      type: 'ask',
      price,
      editionId,
      expiresInDays,
      isBulk,
    }),
  })
  const data = await response.json()
  return data as CreateInitialAskResponse
}

export const editAsk = async (
  askId: number,
  payload: {
    expiresInDays: number
    price?: number
    dhlInsurance?: boolean
    editionId?: number
  }
) => {
  const response = await fetch(`${API_BASEPATH}/api/asks/${askId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(payload),
  })
  const data = await response.json()
  return data as EditAskResponse
}

export const getUserAsks = async (query = '') => {
  const response = await fetch(
    `${API_BASEPATH}/api/asks/user/history?${query}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )
  const data = await response.json()
  return data as GetUserAsksResponse
}

export const getUserAsksStats = async () => {
  const response = await fetch(
    `${API_BASEPATH}/api/asks/user/history?type=stats`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )
  const data = await response.json()
  return data as {
    liveAsksCount: number
    soldAsksCount: number
    pendingAsksCount: number
    expiredAsksCount: number
    completedSales: number
    inProgressSales: number
    soldAsksSum: number
    artistsRoyalties: number
    requiresAction: boolean
  }
}

export const getAsk = async (askId: number, query?: string) => {
  const response = await fetch(`${API_BASEPATH}/api/asks/${askId}?${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await response.json()
  return data as GetAskResponse
}

export const getAsks = async (query?: string) => {
  const response = await fetch(`${API_BASEPATH}/api/asks?${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await response.json()
  return data as GetAsksResponse
}

export const addAskDocuments = async (
  askId: number,
  type: 'coa' | 'artwork' | 'provenence' | 'editionNumber',
  formData: FormData
) => {
  const response = await fetch(
    `${API_BASEPATH}/api/asks/${askId}/documents?type=${type}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    }
  )
  const data = await response.json()
  return data as AddAskDocumentResponse
}

export const clearAskDocuments = async (askId: number) => {
  const response = await fetch(
    `${API_BASEPATH}/api/asks/${askId}/documents?clearFiles=true`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )
  const data = await response.json()
  return data
}

export const deleteAskDocument = async (askId: number, documentId: number) => {
  const response = await fetch(
    `${API_BASEPATH}/api/asks/${askId}/documents/${documentId}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({}),
    }
  )
  const data = await response.json()
  return data as DeleteAskDocumentResponse
}

export const addAskDocumentsComments = async (
  askId: number,
  coaComments?: string,
  artworkComments?: string,
  provenenceComments?: string,
  editionNumberComments?: string
) => {
  const response = await fetch(`${API_BASEPATH}/api/asks/${askId}/comments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      coaComments,
      artworkComments,
      provenenceComments,
      editionNumberComments,
    }),
  })
  const data = await response.json()
  return data as AddAskDocumentsCommentsResponse
}

export const placeAsk = async (
  askId: number,
  shipDays: boolean,
  newCondition: boolean,
  dhlInsurance: boolean,
  bidId?: number,
  liveAskId?: number
) => {
  const response = await fetch(
    `${API_BASEPATH}/api/asks/${askId}/place/${bidId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        newCondition,
        shipDays,
        dhlInsurance,
        liveAskId,
      }),
    }
  )
  const data = await response.json()
  return data as PlaceAskResponse
}

export const sellNow = async (editionId: number, isBulk?: boolean) => {
  const response = await fetch(`${API_BASEPATH}/api/asks`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({ type: 'sell', editionId, isBulk }),
  })
  const data = await response.json()
  return data as SellNowResponse
}

export const deleteAsk = async (askId: number) => {
  const response = await fetch(`${API_BASEPATH}/api/asks/${askId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await response.json()
  return data as DeleteAskResponse
}

export const extendAllAsks = async (expiresInDays: number) => {
  const response = await fetch(`${API_BASEPATH}/api/asks/extend-all`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({ expiresInDays }),
  })
  return (await response.json()) as { error?: Err }
}

export const getBulkAsks = async () => {
  const response = await fetch(`${API_BASEPATH}/api/asks/user/bulk`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  return (await response.json()) as BulkAsksResponse
}

export const placeBulkAsks = async (
  asks: {
    id: string
    documentStatus: string
    price: number
    userId: string
    type: SellingTradeType
    highestBidId?: string | null
  }[]
) => {
  const response = await fetch(`${API_BASEPATH}/api/asks/bulk/place`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({ asks }),
  })
  return await response.json()
}

export const voidBulkAsks = async () => {
  const response = await fetch(`${API_BASEPATH}/api/asks/bulk/void`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  return (await response.json()) as { error?: Err }
}
