/* eslint-disable quotes */
import { FormikValues } from 'formik'
import { ParsedUrlQuery } from 'querystring'
import { diacritics } from '../constants'

export const tw = (strings: TemplateStringsArray, ...values: any[]) =>
  [...strings, ...values].join(' ')

export const titleCase = (str?: string) => {
  if (!str) return ''

  return str
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export const senteceCase = (str?: string) => {
  if (!str) return

  const lowerCased = str.toLowerCase()

  return lowerCased.slice(0, 1).toUpperCase() + lowerCased.slice(1)
}

export const isTextClamped = (element: HTMLElement) =>
  element.scrollHeight > element.clientHeight

export const queryToValues = (
  query: ParsedUrlQuery,
  oldValues: FormikValues = {}
) => {
  const newValues: FormikValues = new Object(oldValues)
  for (const [key, value] of Object.entries(query)) {
    if (!value) continue
    if (typeof value === 'string') {
      switch (key) {
        case 'priceMin':
        case 'priceMax':
        case 'releaseDateMin':
        case 'releaseDateMax':
          newValues[key] = parseInt(value)
          break
        case 'sortBy':
        case 'priceRange':
        case 'search':
          newValues[key] = value
          break
        default:
          newValues[key] = [value]
          break
      }
      continue
    }
    newValues[key] = value
  }
  return newValues
}

export const formatCurrency = (
  value: number | undefined,
  options: Intl.NumberFormatOptions = {
    currency: 'GBP',
    minimumFractionDigits: 0,
    style: 'currency',
  }
) => {
  if (!value || isNaN(value)) return '--'

  return new Intl.NumberFormat('en-UK', {
    style: options.style ?? 'currency',
    currency: options.currency ?? 'GBP',
    minimumFractionDigits: options.minimumFractionDigits || 0,
    localeMatcher: 'lookup',
    ...options,
  }).format(value)
}

export const getEditionFullName = (editionName?: string) => {
  switch (editionName) {
    case 'Artist':
      // eslint-disable-next-line quotes
      return "Artist's Proof (AP)"
    case 'Printer':
      // eslint-disable-next-line quotes
      return "Printer's Proof (PP)"
    case 'Main':
    default:
      return 'Main Edition'
  }
}

export const getEditionShortName = (
  editionName?: string,
  withBrackets = true
) => {
  switch (editionName) {
    case 'Artist':
      return withBrackets ? '(AP)' : 'AP'
    case 'Printer':
      return withBrackets ? '(PP)' : 'PP'
    case 'Main':
    default:
      return ''
  }
}

export const queryWithDiacritics = (query?: string, fieldName = 'name') => {
  const combinations: object[] = []

  if (query && typeof query === 'string' && query !== 'undefined') {
    combinations.push({
      [fieldName]: {
        $notNull: true,
        $containsi: query.trim(),
      },
    })

    Object.entries(diacritics).forEach(entry => {
      const letter = entry[0]
      const letterDiacritics = entry[1]
      const regex = new RegExp(letter)

      if (query.includes(letter)) {
        letterDiacritics.forEach(diacritic => {
          const newQuery = query.replace(regex, diacritic).trim()

          combinations.push({
            [fieldName]: {
              $notNull: true,
              $containsi: newQuery,
            },
          })
        })
      }
    })
  }

  return combinations
}

export const isValidPhoneNumber = async (
  value?: string,
  validateMissing?: boolean
) => {
  if (!value) {
    if (validateMissing)
      return { valid: false, error: 'Phone number is missing' }
    return { valid: true, error: null }
  }

  const { parsePhoneNumber, isValidPhoneNumber: isValidReactPhoneNumber } =
    await import('react-phone-number-input')

  const parsedPhoneNumber = parsePhoneNumber(value)

  if (!parsedPhoneNumber)
    return { valid: false, error: 'Phone number is missing' }

  const { country, countryCallingCode } = parsedPhoneNumber

  const isGBNumber = country === 'GB' || countryCallingCode === '44'

  if (!isValidReactPhoneNumber(value))
    return {
      valid: false,
      error: isGBNumber
        ? "Please enter a valid UK phone number (start '7', '07', or '447' followed by 9 digits)"
        : 'Please enter a valid phone number (check country code)',
    }

  return { valid: true, error: null }
}

export const escapeRegExp = (input: string) => {
  return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export const getArtworkNameWithEdition = (
  artworkName?: string,
  editionName?: string
) => {
  if (!artworkName) return ''

  if (!editionName) return artworkName

  switch (editionName) {
    case 'Artist':
      return `${artworkName} (AP)`
    case 'Printer':
      return `${artworkName} (PP)`
    case 'Main':
    default:
      return artworkName
  }
}

export const getLink = (input?: string) => {
  if (!input) return ''

  if (input.startsWith('http')) return input

  return `https://${input}`
}

export const isValidUrl = (input: string) => {
  let url: URL

  try {
    url = new URL(input)
  } catch (err) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}
