import { IUser } from '@/types'
import { API_BASEPATH, Err } from '.'

export const authErrors = {
  accountNotConfirmed: 'Your account email is not confirmed',
}

export type LoginCredentials = {
  email: string
  password: string
}

export type RegisterCredentials = {
  email: string
  password: string
  firstName: string
  lastName: string
  username: string
}

export type ConfirmEmailCredentials = {
  confirmationToken: string
}

export type SendConfirmationEmailCredentials = {
  email: string
}

export type ForgotPasswordCredentials = {
  email: string
}

export type ResetPasswordCredentials = {
  password: string
  passwordConfirmation: string
  verificationToken: string
}

export type VerifyResetPasswordTokenCredentials = {
  verificationToken: string
}

type Response = {
  jwt?: string
  user?: IUser
  error?: Err
  message?: {
    0: {
      messages: {
        0: {
          message: string
        }
      }
    }
  }
}

type RegisterResponse = {
  user?: IUser
  error?: Err
}

type SendConfirmationEmailResponse = {
  email?: string
  sent?: boolean
  error?: Err
}

type ForgotPasswordResponse = {
  ok?: boolean
  error?: Err
}

type VerifyResetPasswordTokenResponse = {
  ok?: boolean
  error?: Err
}

export const login = async ({ email, password }: LoginCredentials) => {
  const response = await fetch(`${API_BASEPATH}/api/auth/local/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      identifier: email,
      password,
    }),
  })
  const data = await response.json()
  return data as Response
}

export const register = async ({
  firstName,
  lastName,
  username,
  email,
  password,
}: RegisterCredentials) => {
  const response = await fetch(`${API_BASEPATH}/api/auth/local/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      firstName,
      lastName,
      username,
      email,
      password,
    }),
  })
  const data = await response.json()
  return data as RegisterResponse
}

export const confirmEmail = async ({
  confirmationToken,
}: ConfirmEmailCredentials) => {
  const response = await fetch(
    `${API_BASEPATH}/api/auth/email-confirmation?confirmation=${confirmationToken}`
  )
  const data = await response.json()
  return data as Response
}

export const sendConfirmationEmail = async ({
  email,
}: SendConfirmationEmailCredentials) => {
  const response = await fetch(
    `${API_BASEPATH}/api/auth/send-email-confirmation`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    }
  )
  const data = await response.json()
  return data as SendConfirmationEmailResponse
}

export const forgotPassword = async ({ email }: ForgotPasswordCredentials) => {
  const response = await fetch(`${API_BASEPATH}/api/auth/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  })
  const data = await response.json()
  return data as ForgotPasswordResponse
}

export const resetPassword = async ({
  password,
  passwordConfirmation,
  verificationToken,
}: ResetPasswordCredentials) => {
  const response = await fetch(`${API_BASEPATH}/api/auth/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code: verificationToken,
      password,
      passwordConfirmation,
    }),
  })
  const data = await response.json()
  return data as Response
}

export const verifyResetPasswordToken = async ({
  verificationToken,
}: VerifyResetPasswordTokenCredentials) => {
  const response = await fetch(`${API_BASEPATH}/api/auth/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code: verificationToken,
    }),
  })
  const data = await response.json()
  return data as VerifyResetPasswordTokenResponse
}
