import { HTMLProps, cloneElement } from 'react'

const IconsMap = {
  chevronDown: (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.9263 10L11.9631 16.9632L4.99993 10'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),

  plus: (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 3V21'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 12L21 12'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),

  search: (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22 22L18 18'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),

  check: (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 12.3135L9.65685 17.9703L20.9706 6.65662'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  ),

  heart: (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.961'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.651 5.28597C16.651 5.68744 16.651 6.08894 16.651 6.4904C16.4909 7.5944 16.0731 8.59268 15.3978 9.48519C14.729 10.3272 13.9966 11.1139 13.2005 11.8452C11.8285 13.0465 10.4505 14.2401 9.06641 15.4259C8.56728 15.8383 8.06813 15.8383 7.56901 15.4259C6.1849 14.2401 4.80687 13.0465 3.4349 11.8452C2.63883 11.1139 1.90641 10.3272 1.23763 9.48519C0.562305 8.59268 0.144551 7.5944 -0.015625 6.4904C-0.015625 6.08894 -0.015625 5.68744 -0.015625 5.28597C0.174908 3.6836 0.934456 2.43035 2.26302 1.52621C3.58525 0.791388 4.95244 0.693731 6.36458 1.23324C7.14847 1.61029 7.79951 2.1474 8.31771 2.84457C9.69056 1.05516 11.4592 0.496352 13.6237 1.16814C14.9382 1.71223 15.8442 2.65081 16.3418 3.98389C16.4808 4.4139 16.5839 4.84795 16.651 5.28597ZM4.18359 1.90056C5.29378 1.79493 6.24323 2.1313 7.0319 2.90967C7.35518 3.27627 7.65358 3.66146 7.92708 4.06527C8.1875 4.21719 8.44792 4.21719 8.70833 4.06527C8.98184 3.66146 9.28024 3.27627 9.60352 2.90967C10.7194 1.86186 11.9944 1.61772 13.4284 2.17725C14.7281 2.84886 15.4605 3.91765 15.6257 5.38363C15.7165 6.24899 15.5755 7.07364 15.2025 7.85759C14.8595 8.52207 14.4363 9.12969 13.9329 9.6805C13.4844 10.129 13.0341 10.5794 12.582 11.0314C11.1727 12.2675 9.75127 13.4882 8.31771 14.6935C6.56908 13.246 4.86009 11.7486 3.19076 10.2013C2.56963 9.56589 2.02709 8.87142 1.56315 8.11801C0.889443 6.81814 0.813486 5.4835 1.33529 4.1141C1.89312 2.86672 2.84255 2.12887 4.18359 1.90056Z'
        fill='#6663C9'
      />
    </svg>
  ),

  filledHeart: (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_15083_115017)'>
        <path
          opacity='0.994'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.9805 6.34473C19.9805 6.82649 19.9805 7.30829 19.9805 7.79004C19.7882 9.11485 19.287 10.3128 18.4766 11.3838C17.674 12.3943 16.7951 13.3383 15.8398 14.2158C14.1935 15.6573 12.5398 17.0897 10.8789 18.5127C10.28 19.0075 9.68098 19.0075 9.08203 18.5127C7.42109 17.0897 5.76746 15.6573 4.12109 14.2158C3.16581 13.3383 2.28691 12.3943 1.48438 11.3838C0.673984 10.3128 0.17268 9.11485 -0.0195312 7.79004C-0.0195312 7.30829 -0.0195312 6.82649 -0.0195312 6.34473C0.209109 4.42188 1.12057 2.91798 2.71484 1.83301C4.30152 0.951228 5.94215 0.83404 7.63672 1.48145C8.57738 1.93391 9.35863 2.57844 9.98047 3.41504C11.6279 1.26776 13.7503 0.597185 16.3477 1.40333C17.925 2.05624 19.0123 3.18254 19.6094 4.78223C19.7762 5.29825 19.8999 5.81911 19.9805 6.34473Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_15083_115017'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  ),

  hamburgerMenu: (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 5H23'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 12H23'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 19H23'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),

  spinnerOutside: (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.1291 12C11.1624 12 12.0149 11.1574 11.8544 10.1367C11.7075 9.20204 11.4503 8.28591 11.0866 7.4078C10.4835 5.95189 9.59958 4.62902 8.48528 3.51472C7.37098 2.40042 6.04811 1.5165 4.5922 0.913445C3.71409 0.54972 2.79795 0.292459 1.86334 0.145551C0.842607 -0.0148944 -9.53674e-07 0.837631 0 1.8709C0 2.90417 0.848082 3.72074 1.85495 3.95283C2.29983 4.05538 2.73649 4.19488 3.16027 4.37042C4.1622 4.78543 5.07258 5.39373 5.83943 6.16057C6.60627 6.92742 7.21457 7.83779 7.62958 8.83972C7.80512 9.26351 7.94462 9.70017 8.04717 10.1451C8.27926 11.1519 9.09583 12 10.1291 12Z'
        fill='#262338'
      />
    </svg>
  ),

  spinnerInside: (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.3'
        d='M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM3.7418 12C3.7418 16.5609 7.43912 20.2582 12 20.2582C16.5609 20.2582 20.2582 16.5609 20.2582 12C20.2582 7.43912 16.5609 3.7418 12 3.7418C7.43912 3.7418 3.7418 7.43912 3.7418 12Z'
        fill='#262338'
      />
    </svg>
  ),
} as const

type Props = {
  name: keyof typeof IconsMap
  size?: number
} & HTMLProps<SVGSVGElement>

export const StaticIcon = ({ name, size = 16, ...props }: Props) => {
  return cloneElement(IconsMap[name], { ...props, width: size, height: size })
}
