import { KeyboardEvent, MouseEvent, PointerEvent } from 'react'

export function isNavigationClickEvent(event: MouseEvent<HTMLAnchorElement>) {
  return (
    event.button === 0 && // left click
    !event.ctrlKey &&
    !event.shiftKey &&
    !event.altKey && 
    !event.metaKey
  )
}

export function isNavigationPointerDownEvent(
  event: PointerEvent<HTMLAnchorElement>
) {
  return (
    event.pointerType === 'touch' ||
    (event.pointerType === 'mouse' && isNavigationClickEvent(event))
  )
}

export function isNavigationKeyDownEvent(
  event: KeyboardEvent<HTMLAnchorElement>
) {
  return (
    event.code === 'Enter' && !event.ctrlKey && !event.shiftKey && !event.altKey
  )
}

/**
 * Wait for an element before resolving a promise
 * @param {String} querySelector - Selector of element to wait for
 * @param {Integer} timeout - Milliseconds to wait before timing out, or 0 for no timeout
 */
export function waitForElement(
  querySelector: string,
  timeout: number
): Promise<NodeListOf<Element>> {
  return new Promise((resolve, reject) => {
    let timer: ReturnType<typeof setTimeout>
    const elements = document.querySelectorAll(querySelector)
    if (elements.length) return resolve(elements)

    const observer = new MutationObserver(() => {
      const elements = document.querySelectorAll(querySelector)
      if (elements.length) {
        observer.disconnect()
        if (timer) clearTimeout(timer)
        return resolve(elements)
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
    if (timeout)
      timer = setTimeout(() => {
        observer.disconnect()
        reject()
      }, timeout)
  })
}
