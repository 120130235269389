import classNames from 'classnames'
import { Spinner } from 'components/common/Spinner'
import { ComponentProps } from 'react'
import { PropsWithClassName } from 'types'

interface Props extends PropsWithClassName, ComponentProps<typeof Spinner> {}

export function CenteredSpinner({ className, ...props }: Props) {
  return (
    <div
      className={classNames(
        'absolute left-0 top-0 w-full h-full flex items-center justify-center',
        className
      )}
    >
      <Spinner {...props} />
    </div>
  )
}
