import { IFeatureFlag } from '@/types'
import { API_BASEPATH } from '.'

export const getFeatureFlags = async (query?: string) => {
  const response = await fetch(`${API_BASEPATH}/api/feature-flags?${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const data = await response.json()
  return data as { data: IFeatureFlag[] }
}
