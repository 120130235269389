import { artistCardPopulation, getArtists } from 'api/artists'
import { artworkCardPopulation, getArtworks } from 'api/artworks'
import {
  getLatestSearches,
  saveSearchedArtist,
  saveSearchedArtwork,
} from 'api/latest-searches'
import qs from 'qs'
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import {
  mapIArtistsToArtistCards,
  mapIArtworksToArtworkCards,
} from 'utils/data'
import { ImageExtendedFormat } from 'utils/image'
import { titleCase } from 'utils/strings'
import { useAuth } from '../contexts'

const fetchArtworks = async (searchedIds: string[], maxResults?: number) => {
  if (!searchedIds?.length) return

  const query = qs.stringify(
    {
      filters: {
        id: {
          $in: searchedIds.filter(id => !isNaN(Number(id))),
        },
        $or: [
          {
            isDeleted: {
              $eq: false,
            },
          },
          {
            isDeleted: {
              $notNull: false,
            },
          },
        ],
      },
      pagination: {
        page: 1,
        pageSize: maxResults || 100,
      },
      ...artworkCardPopulation,
    },
    { encodeValuesOnly: true }
  )

  const { data: artworks } = await getArtworks(query)

  return artworks
}

const fetchArtists = async (searchedIds: string[], maxResults?: number) => {
  if (!searchedIds?.length) return

  const query = qs.stringify(
    {
      filters: { id: { $in: searchedIds } },
      pagination: {
        page: 1,
        pageSize: maxResults || 100,
      },
      ...artistCardPopulation,
    },
    { encodeValuesOnly: true }
  )

  const { data: artists } = await getArtists(query)

  return artists
}

const sortByIds = <T extends { id: number }>(
  searchedIds: string[],
  inputArr?: T[]
): T[] => {
  if (!inputArr) return []

  return inputArr?.sort((item1, item2) => {
    const index1 = searchedIds.findIndex(id => String(item1.id) === id)
    const index2 = searchedIds.findIndex(id => String(item2.id) === id)

    return index1 - index2
  })
}

export type Config = {
  entities: 'artwork' | 'artist'
  key?: string
  computeIds?: boolean
  computeEntities?: boolean
  maxResults?: number
  imageFormat?: ImageExtendedFormat
}

type Return<T> = {
  ids: string[] | null
  saveSearchedIds: (id: number) => void
  getSearchedIds: () => Promise<string[]>
  searches?: T[]
}

export const useLatestSearches = <T = unknown>({
  key,
  entities,
  computeIds = false,
  computeEntities = false,
  maxResults,
  imageFormat = 'thumbnail',
}: Config): Return<T> => {
  const { user, isAuthenticated } = useAuth()
  const [ids, setIds] = useState<string[] | null>(null)

  const localStorageKey = `latest${titleCase(entities)}`

  const { data: searches } = useQuery(
    [key, entities, maxResults, computeEntities, imageFormat, user],
    async () => {
      if (!entities || !computeEntities) return []

      const searchedIds = ids || (await getSearchedIds())

      let searches

      if (entities === 'artist') {
        searches = await fetchArtists(searchedIds, maxResults)
      } else {
        searches = await fetchArtworks(searchedIds, maxResults)
      }

      if (!searches) return []

      const sortedSearches = sortByIds(searchedIds, searches)

      if (!sortedSearches) return []

      if (entities === 'artist')
        return mapIArtistsToArtistCards(
          sortedSearches,
          imageFormat
        ) as unknown as T[]
      else
        return mapIArtworksToArtworkCards(
          sortedSearches,
          imageFormat
        ) as unknown as T[]
    },
    { refetchOnWindowFocus: false }
  )

  const getIdsFromLocalStorage = useCallback((): string[] => {
    if (localStorage) {
      let storedSearches = localStorage.getItem(localStorageKey)

      if (storedSearches) {
        try {
          storedSearches = JSON.parse(storedSearches)
        } catch (error) {
          storedSearches = null
        }

        if (Array.isArray(storedSearches)) {
          return storedSearches
        }
      }
    }

    return []
  }, [localStorageKey])

  const getIdsFromDb = useCallback(async () => {
    try {
      const results = await getLatestSearches(entities)

      if (!results?.length) return []

      const resultsIds = results?.map(result => result[`${entities}Id`])

      return resultsIds as string[]
    } catch (err) {
      return []
    }
  }, [entities])

  const getSearchedIds = useCallback(async () => {
    let ids: string[] = []

    if (user && isAuthenticated) {
      ids = await getIdsFromDb()
    } else {
      ids = getIdsFromLocalStorage()
    }
    return ids
      ?.filter(id => Boolean(id) && !isNaN(Number(id)))
      ?.slice(0, maxResults)
  }, [user, isAuthenticated, getIdsFromDb, getIdsFromLocalStorage, maxResults])

  const saveIdsToLocalStorage = useCallback(
    (id: number) => {
      let newSearches = ids ?? getIdsFromLocalStorage()

      if (Array.isArray(newSearches)) {
        newSearches = newSearches.filter(searchId => searchId !== String(id))

        newSearches.unshift(String(id))

        newSearches = newSearches.slice(0, 20)

        localStorage.setItem(localStorageKey, JSON.stringify(newSearches))
      }
    },
    [localStorageKey, getIdsFromLocalStorage, ids]
  )

  const saveIdsToDb = useCallback(
    async (id: number) => {
      if (entities === 'artwork') {
        await saveSearchedArtwork(String(id))
      }

      if (entities === 'artist') {
        await saveSearchedArtist(String(id))
      }
    },
    [entities]
  )

  const saveSearchedIds = useCallback(
    (id: number) => {
      if (user) {
        saveIdsToDb(id)
      } else {
        saveIdsToLocalStorage(id)
      }
    },
    [saveIdsToLocalStorage, saveIdsToDb, user]
  )

  const setIdsHandler = useCallback(async () => {
    const searchedIds = await getSearchedIds()

    setIds(searchedIds ?? [])
  }, [getSearchedIds])

  useEffect(() => {
    if (computeIds) {
      setIdsHandler().catch()
    }
  }, [computeIds, setIdsHandler])

  return {
    ids,
    saveSearchedIds,
    getSearchedIds,
    searches,
  }
}
