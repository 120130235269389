import { AskStatus } from 'types'
import { Icon } from 'components/common/Icon'
import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { ReactNode } from 'react'
import { formatCurrency } from 'utils/strings'
import { Price } from 'components/common/Price'

const Modal = dynamic(
  () => import('components/common/Modal').then(m => m.Modal),
  {
    ssr: false,
  }
)

type Props = {
  askStatus: AskStatus
  tradeValue?: number
  isEdit?: boolean
  title?: ReactNode
}

export const AskPlacedModal = ({
  askStatus,
  tradeValue,
  isEdit,
  title,
}: Props) => {
  const { t } = useTranslation('common')

  return (
    <Modal
      className='relative m-auto mx-6 h-fit w-full rounded-lg sm:!max-w-[400px]'
      header={
        <h5 className='flex items-center text-xl'>
          {title}

          {!title && (
            <>
              {isEdit ? (
                <>
                  <Icon
                    name='filled-success'
                    filled
                    size={24}
                    className='mr-2 text-success-default'
                  />
                  {t('common:listing_updated')}
                </>
              ) : (
                <>
                  {askStatus === 'PendingAuthentication' && (
                    <>
                      <Image
                        src={'/files/pending.svg'}
                        width={24}
                        height={24}
                        alt='Pending'
                      />
                      <span className='!ml-2'>{t('ask_pending')}</span>
                    </>
                  )}
                  {(askStatus === 'Approved' || askStatus === 'Done') && (
                    <>
                      <Icon
                        name='filled-success'
                        filled
                        size={24}
                        className='mr-2 text-success-default'
                      />
                      {t('listing_placed')}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </h5>
      }
      body={
        <>
          {askStatus === 'PendingAuthentication' && (
            <div className='text-sm text-cool-600'>
              <p className='font-medium text-cool-800'>
                {t('your_ask_for')}{' '}
                <Price formattingOptions={{ minimumFractionDigits: 2 }}>
                  {tradeValue}
                </Price>{' '}
                {t('is_pending_until')}
              </p>
              <br />
              <ol className='flex list-outside list-decimal flex-col space-y-2 pl-4 text-sm'>
                <li>{t('we_will_review')}</li>
                <li>{t('once_this_process_is_complete')}</li>
                <li>{t('if_successful')}</li>
              </ol>
            </div>
          )}

          {(askStatus === 'Approved' || askStatus === 'Done') && (
            <div className='text-sm text-cool-600'>
              {tradeValue && (
                <>
                  <p
                    className='text-base font-medium text-cool-800'
                    data-e2e='trade-success-message'
                    translate='no'
                  >
                    {t('your_trade_for_x_is_live', {
                      tradeType: 'listing',
                      sum: formatCurrency(tradeValue, {
                        minimumFractionDigits: 2,
                      }),
                    })}
                  </p>

                  <br />
                </>
              )}
              <p className='mb-2 font-medium text-cool-800'>{t('remember')}</p>
              <ol className='flex list-outside list-decimal flex-col space-y-2 pl-4 text-sm'>
                <li>{t('when_a_buyer_accepts')}</li>
                <li>{t('you_can_edit_ask')}</li>
                <li>{t('all_artworks_are_undamaged')}</li>
              </ol>
            </div>
          )}
        </>
      }
    />
  )
}
