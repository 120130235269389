import { API_BASEPATH } from 'api'

const routes = {
  homepage: '/',
  articles: '/articles',
  artworks: '/artworks',
  artists: '/artists',
  sell: '/sell',
  askOrSell: {
    base: '/ask-or-sell',
    authentication: '/ask-or-sell/authentication',
    shippingAndBilling: '/ask-or-sell/shipping-and-billing',
    reviewSale: '/ask-or-sell/review-sale',
    submitDocuments: '/ask-or-sell/submit-documents',
  },
  bidOrBuy: {
    base: '/bid-or-buy',
    shippingAndBilling: '/bid-or-buy/shipping-and-billing',
    reviewBid: '/bid-or-buy/review-bid',
  },
  howItWorks: {
    base: '/how-it-works',
    guarantee: '/how-it-works/guarantee',
    shipping: '/how-it-works/shipping',
    fees: '/how-it-works/fees',
    forArtists: '/how-it-works/for-artists',
    forSellers: '/how-it-works/for-sellers',
    forBuyers: '/how-it-works/for-buyers',
    fairartDatabase: '/how-it-works/fairart-database',
  },
  login: '/login',
  register: '/register',
  cookiePolicy: '/cookie-policy',
  cookiesPolicyTermly:
    'https://app.termly.io/document/cookie-policy/c0608af0-6cac-4f20-b802-d1515c17d448',
  termsAndConditions: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  myAccount: {
    base: '/my-account',
    buying: '/my-account/buying',
    selling: '/my-account/selling',
    settings: '/my-account/settings',
    order: '/my-account/buying/order',
    sale: '/my-account/selling/sale',
  },
  thanks: {
    base: '/thanks',
  },
  bulkUpload: {
    base: '/bulk-upload',
    shippingAndBilling: '/bulk-upload/shipping-and-billing',
    addListings: '/bulk-upload/add-listings',
    reviewListings: '/bulk-upload/review-listings',
  },
  following: {
    base: '/following',
    artists: '/following?tab=following',
    artworks: '/following?tab=saved',
  },
  invest: {
    base: '/invest',
  },
  googleAuth: `${API_BASEPATH}/api/connect/google`,
  appleAuth: `${API_BASEPATH}/api/connect/apple`,
  releaseCalendar: {
    base: '/release-calendar',
  },
  notifications: '/notifications',
  intercom: {
    base: 'https://intercom.help/fairart/en/',
    billingDetails:
      'https://intercom.help/fairart/en/articles/6772114-billing-details',
    canISellFramed:
      'https://intercom.help/fairart/en/articles/6404424-can-i-sell-framed-artworks',
    authStatuses:
      'https://intercom.help/fairart/en/articles/6955904-what-are-authentication-statuses-on-fairart',
    collectorOutsideUK:
      'https://intercom.help/fairart/en/articles/6404428-i-am-a-collector-based-outside-of-the-uk-can-i-use-fairart',
    aappDifference:
      'https://intercom.help/fairart/en/articles/9037383-what-is-the-difference-between-main-edition-ap-and-pp',
  },
  dhlDropOffPoint:
    'https://locator.dhl.com/results?language=en&languageCountryCode=GB&resultUom=mi&light=n&countryCode=GB&clientAppCode=gspl&address=SE19TG&capability=81,74',
}

export default routes
