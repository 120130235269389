import { Children, HTMLProps } from 'react'
import { formatCurrency } from 'utils/strings'

type Props = {
  formattingOptions?: Intl.NumberFormatOptions
} & Omit<HTMLProps<HTMLSpanElement>, 'classID'>

export const Price = ({ children, formattingOptions, ...props }: Props) => {
  const internalProps = { ...props, translate: 'no' } as const

  const childrenArray = Children.toArray(children)

  const Fallback = () => <span {...internalProps}>£--</span>

  if (!childrenArray.length) return <Fallback />

  if (childrenArray.length > 1) {
    console.error(
      'Cannot pass multiple children to Price component, passed children:',
      childrenArray
    )
    return <Fallback />
  }

  if (typeof children !== 'string' && typeof children !== 'number') {
    console.error(
      'Price component children must be of string/number type, passed type: ',
      typeof children
    )
    return <Fallback />
  }

  const numValue = Number(children)

  if (!numValue || isNaN(numValue)) {
    if (isNaN(numValue)) {
      console.error(
        'Children passed to price component are invalid numbers, passed children',
        childrenArray
      )
    }
    return <Fallback />
  }

  return (
    <span {...internalProps}>
      {formatCurrency(numValue, formattingOptions)}
    </span>
  )
}
