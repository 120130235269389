import { Icon } from 'components/common/Icon'
import { ToastContentProps } from 'react-toastify'

export const ToastSuccessIcon = () => (
  <Icon name='filled-success' filled size={24} className='text-emerald-800' />
)

export const ToastErrorIcon = () => (
  <Icon name='filled-close' filled size={24} className='text-danger-dark' />
)

export const ToastCloseButton = ({
  closeToast,
}: Partial<ToastContentProps>) => {
  return (
    <Icon
      name='close'
      size={24}
      className='flex items-center text-cool-500 transition-all duration-200 ease-in-out hover:scale-110'
      onClick={closeToast}
    />
  )
}
