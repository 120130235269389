import { create } from 'zustand'

type Config = {
  direction?: 'left' | 'right'
  heading?: React.ReactNode
  body?: React.ReactNode
  className?: string
  overlayClassName?: string
  headingClassName?: string
  hasSeparator?: boolean
  hasIcon?: boolean
}

type State = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  config: Config
  setConfig: (config: Config) => void
}

const initialConfig: Config = {
  direction: 'left',
}

export const useDrawer = create<State>(set => {
  return {
    isOpen: false,
    setIsOpen: isOpen => {
      set(() => ({ isOpen }))
    },
    config: { direction: 'left' },
    setConfig: config => {
      set(() => ({ config: { ...initialConfig, ...config } }))
    },
  }
})
