import { Err } from 'api'
import { buyNow } from 'api/bids'
import absoluteUrl from 'next-absolute-url'
import { useRouter } from 'next/router'
import { Artist, Edition } from 'utils/data'
import { createRedirect, getArtworkUrlFromRoot } from 'utils/urls'

type Config = {
  onError?: (error?: Err) => void
}

type Payload = {
  price: number
  currentEdition: Edition
  askId: number
}

export const useBuyNow = ({ onError }: Config = {}) => {
  const router = useRouter()

  return async ({ price, currentEdition, askId }: Payload) => {
    const res = await buyNow(currentEdition.id, askId)

    // if (error || !redirectUrl) {
    //   onError?.(error)
    //   return
    // }

    // router
    //   .push(
    //     { query: { ...router.query, bidId, action: 'buy', price } },
    //     undefined,
    //     {
    //       shallow: true,
    //     }
    //   )
    //   .then(() => createRedirect(redirectUrl, '_self'))
  }
}
