import { create } from 'zustand'

type State = {
  query: string
  setQuery: (query: string) => void
}

export const useSearch = create<State>((set, get) => {
  return {
    query: '',
    setQuery: query => {
      if (query === get()?.query) return

      set(() => ({ query }))
    },
  }
})
