module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': ['common', '404', 'notifications'],
    '/': ['home', 'release_calendar'],
    '/[...slug]': ['release_calendar', 'single_artwork'],
    '/login': ['login'],
    '/register': ['register'],
    '/sell': ['sell'],
    '/artists': ['artists'],
    '/artists/[...slug]': ['single_artist'],
    '/artworks': ['artworks'],
    '/collections': ['artworks', 'collections'],
    '/collections/[name]': ['artworks', 'collections'],
    '/artworks/[...slug]': ['single_artwork'],
    '/ask-or-sell/[...slug]': [
      'ask_or_sell',
      'shipping_and_billing_form',
      'authentication',
      'review_trade_form',
      'single_artwork',
    ],
    '/bid-or-buy/[...slug]': [
      'buy_or_bid',
      'shipping_and_billing_form',
      'review_trade_form',
    ],
    '/how-it-works': ['how_it_works'],
    '/how-it-works/fees': ['how_it_works'],
    '/how-it-works/for-artists': ['how_it_works'],
    '/how-it-works/for-buyers': ['how_it_works'],
    '/how-it-works/for-sellers': ['how_it_works'],
    '/how-it-works/guarantee': ['how_it_works'],
    '/how-it-works/shipping': ['how_it_works'],
    '/how-it-works/fairart-database': ['how_it_works'],
    '/my-account/settings': ['settings'],
    '/my-account/selling': ['my_account'],
    '/my-account/selling/sale/[artworkId]/[orderId]': ['my_account', 'thanks'],
    '/my-account/selling/sale/[artworkId]': ['my_account', 'thanks'],
    '/my-account/buying': ['my_account'],
    '/my-account/buying/order/[artworkId]/[orderId]': ['my_account', 'thanks'],
    '/my-account/buying/order/[artworkId]': ['my_account', 'thanks'],
    '/thanks/[...slug]': ['my_account', 'thanks'],
    '/bulk-upload': ['bulk_upload'],
    '/bulk-upload/[...slug]': ['bulk_upload', 'shipping_and_billing_form'],
    '/following': ['following'],
    '/invest': ['invest'],
    '/release-calendar': ['release_calendar'],
    '/release-calendar/[...slug]': ['release_calendar', 'single_artwork'],
  },
}
