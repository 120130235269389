import { keyframes } from '@stitches/react'

const scaleIn = keyframes({
  '0%': { opacity: 0, transform: 'scale(0)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
})

export const tooltipContentStyle = {
  transformOrigin: 'var(--radix-tooltip-content-transform-origin)',
  animation: `${scaleIn} 0.2s ease-out`,
  background: 'white',
  padding: '1rem 1.5rem',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '0.875rem',
  fontWeight: 400,
  borderRadius: '1rem',
  boxShadow: '0 2px 20px 0 rgba(38,35,56,0.15)',
  color: '#4E4B66',
  minWidth: '100%',
  width: '300px',
  zIndex: 999999,
  '&[data-side="top"]': {
    position: 'relative',
    bottom: '10px',
  },
  '&[data-side="bottom"]': {
    position: 'relative',
    top: '10px',
  },
  '@media screen and (max-width: 450px)': {
    '&[data-side="left"]': { width: '280px' },
    '&[data-side="top"]': {
      width: 'calc(100vw - 120px)',
      right: '10px',
    },
    '&[data-side="bottom"]': {
      width: 'calc(100vw - 120px)',
      right: '10px',
    },
    '&.shipping-and-billing[data-side="top"]': {
      width: 'calc(100vw - 151px)',
      right: '10px',
    },
    '&.shipping-and-billing[data-side="bottom"]': {
      width: 'calc(100vw - 151px)',
      right: '10px',
    },
  },
  '@media screen and (max-width: 374px)': {
    '&[data-side="left"]': { width: '220px' },
  },
}

export const tooltipArrowStyle = {
  fill: 'white',
  animation: `${scaleIn} 0.2s ease-out`,
}
