import { API_BASEPATH } from '.'

interface ISearchedEntity {
  artworkId?: string | null
  artistId?: string | null
  id: number
}

export const getLatestSearches = async (entities: 'artwork' | 'artist') => {
  const response = await fetch(
    `${API_BASEPATH}/api/latest-searches/${entities}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )
  const data = await response.json()
  return data as ISearchedEntity[]
}

export const saveSearchedArtwork = async (artworkId: string) => {
  const response = await fetch(`${API_BASEPATH}/api/latest-searches/artwork`, {
    method: 'POST',
    body: JSON.stringify({ artworkId }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await response.json()
  return data
}

export const saveSearchedArtist = async (artistId: string) => {
  const response = await fetch(`${API_BASEPATH}/api/latest-searches/artist`, {
    method: 'POST',
    body: JSON.stringify({ artistId }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await response.json()
  return data
}
