import React, { PropsWithChildren } from 'react'

class ErrorBoundary extends React.Component<
  PropsWithChildren,
  { hasError: boolean }
> {
  constructor(props: PropsWithChildren) {
    super(props)

    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.log({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) return null

    // if (this.state.hasError) {
    //   return (
    //     <div>
    //       <span className=''>Oops, there is an error!</span>
    //       <button
    //         type='button'
    //         onClick={() => this.setState({ hasError: false })}
    //       >
    //         Try again?
    //       </button>
    //     </div>
    //   )
    // }

    return this.props.children
  }
}

export default ErrorBoundary
