export const registerFetchIntercept = () => {
  if (typeof window === 'undefined') return

  const origFetch = window.fetch
  window.fetch = async (input: RequestInfo | URL, init?: RequestInit) => {
    const response = await origFetch(input as RequestInfo, init)
    if (response.status === 401) {
      console.warn('The user will be logged out')
      const logoutEvent = new Event('logout')
      window.dispatchEvent(logoutEvent)
    }

    return response
  }
}
