import { IMedia } from '@/types'

export const artworksSorts = {
  recommended: [
    'spreadPercentage',
    'releaseDate:desc',
    'numberOfPastSales:desc',
    'name:asc',
    'medium:desc',
  ],
  lowestAsk: ['lowestAsk', 'medium:desc'],
  highestBid: ['highestBid:desc', 'medium:desc'],
  newest: ['releaseDate:desc', 'medium:desc'],
  oldest: ['releaseDate', 'medium:desc'],
  latestAsks: ['latestAskAt:desc'],
  latestBids: ['latestBidAt:desc'],
}

export const sortByQuery = (
  query: string | undefined,
  name1: string,
  name2: string
) => {
  if (!query) return 0

  const idx1 = name1.toLowerCase().indexOf(query)
  const idx2 = name2.toLowerCase().indexOf(query)

  return idx1 - idx2
}

export const sortArtworkImages = (img1?: IMedia, img2?: IMedia) => {
  if (!img1?.attributes?.name) return -1
  if (!img2?.attributes?.name) return 1

  return img1.attributes.name.localeCompare(img2?.attributes?.name, undefined, {
    numeric: true,
    sensitivity: 'base',
  })
}

export const getArtworksSort = (
  sortBy: string | string[] = 'Recommended'
): string[] => {
  let lowerCaseSortBy: string | string[] = 'recommended'

  if (Array.isArray(sortBy))
    lowerCaseSortBy = sortBy.map(sort => sort.toLowerCase())
  else if (typeof sortBy === 'string') lowerCaseSortBy = sortBy.toLowerCase()

  switch (true) {
    case lowerCaseSortBy.includes('highest bid'):
      return artworksSorts.highestBid

    case lowerCaseSortBy.includes('lowest ask'):
      return artworksSorts.lowestAsk

    case lowerCaseSortBy.includes('release date: newest first'):
      return artworksSorts.newest

    case lowerCaseSortBy.includes('release date: oldest first'):
      return artworksSorts.oldest

    case lowerCaseSortBy.includes('latest offers'):
      return artworksSorts.latestBids

    case lowerCaseSortBy.includes('latest listings'):
      return artworksSorts.latestAsks

    case lowerCaseSortBy.includes('recommended'):
    default:
      return artworksSorts.recommended
  }
}
