import routes from 'constants/routes'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useAccess, useAuth, useAuthModal } from '../../contexts'

type Props = {
  children: React.ReactNode
}

const protectedRoutes = [
  routes.myAccount.base,
  routes.thanks.base,
  routes.askOrSell.authentication,
  routes.askOrSell.shippingAndBilling,
  routes.askOrSell.reviewSale,
  routes.askOrSell.submitDocuments,
  routes.bulkUpload.shippingAndBilling,
  routes.bulkUpload.addListings,
  routes.bulkUpload.reviewListings,
  routes.following.base,
]

const routeIsProtected = (url: string) => {
  return Boolean(protectedRoutes.find(route => url.includes(route)))
}

export const RouteGuard = ({ children }: Props) => {
  const router = useRouter()
  const { isAuthenticated } = useAuth()
  const { setIsOpen, setState } = useAuthModal()
  const { hasAccess } = useAccess()

  const authIsDefined = typeof isAuthenticated !== 'undefined'
  const isAuthorized = routeIsProtected(router.asPath) ? isAuthenticated : true

  const redirect = () => {
    if (!hasAccess) return

    router
      .push({ pathname: '/', query: { redirectedFrom: router.asPath } })
      .then(() => {
        setState('login')
        setIsOpen(true)
      })
  }

  const handleRouteChange = (url: string) => {
    // if (!hasAccess) return

    const shouldIntercept =
      routeIsProtected(url) && authIsDefined && !isAuthenticated

    if (shouldIntercept) {
      router.events.emit('routeChangeError')
      redirect()
      throw 'Not authorized'
    }
  }

  useEffect(() => {
    if (!authIsDefined) return

    if (authIsDefined && !isAuthorized) {
      redirect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, router.asPath, isAuthenticated, authIsDefined])

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events, isAuthenticated, authIsDefined])

  if (!isAuthorized) return null

  return <>{children}</>
}
