import { IArtist, IFollowArtist } from '@/types'
import { FollowArtistPreferences, ImageSizes, StrapiPagination } from 'types'
import { API_BASEPATH, Err } from '.'
import qs from 'qs'

export const artistCardPopulation = {
  fields: ['name', 'dateOfBirth'],
  populate: {
    images: {
      fields: ['name', 'formats', 'url'],
    },
    nationalities: {
      fields: ['name', 'alpha2Code'],
    },
  },
}

type Response = {
  data: IArtist[]
  meta: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
}

export type ArtistMediumCount = { label: string; count: number }

type GetArtistMediumsResponse = {
  mediums: ArtistMediumCount[]
}

type FollowArtistRes = {
  id: number
  error?: Err
}

type GetFollowedArtistsRes = {
  data: IFollowArtist[]
  meta: {
    pagination: StrapiPagination
  }
}

type IsArtistFollowedRes = {
  error?: Err
  id?: number
}

export const getArtists = async (query?: string) => {
  const response = await fetch(`${API_BASEPATH}/api/artists?${query}`)
  const data = await response.json()
  return data as Response
}

export const getArtist = async (id: number, query?: string) => {
  const response = await fetch(`${API_BASEPATH}/api/artists/${id}?${query}`)
  const data = await response.json()
  return data as { data: IArtist }
}

export const getArtistMediums = async (id: number) => {
  const response = await fetch(`${API_BASEPATH}/api/artists/mediums/${id}`)
  const data = await response.json()
  return data as GetArtistMediumsResponse
}

export const getTrendingArtists = async () => {
  const response = await fetch(`${API_BASEPATH}/api/artists/features/trending`)
  const data = await response.json()
  return data as {
    id: number
    dateOfBirth?: string
    name?: string
    images?: ImageSizes[]
  }[]
}

export const followArtist = async (
  payload: {
    artistId: number
  } & FollowArtistPreferences
) => {
  const res = await fetch(`${API_BASEPATH}/api/follow-artists`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await res.json()
  return data as FollowArtistRes
}

export const updateFollowPreferences = async (
  followId: number,
  payload: FollowArtistPreferences
) => {
  const res = await fetch(`${API_BASEPATH}/api/follow-artists/${followId}`, {
    method: 'PUT',
    body: JSON.stringify({ data: payload }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  return await res.json()
}

export const getFollowedArtists = async (query?: string) => {
  const res = await fetch(`${API_BASEPATH}/api/follow-artists?${query}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await res.json()
  return data as GetFollowedArtistsRes
}

export const unfollowArtist = async (id: number) => {
  const res = await fetch(`${API_BASEPATH}/api/follow-artists/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  return (await res.json()) as { error?: Err; id?: null }
}

export const isArtistFollowed = async (artistId: number) => {
  const res = await fetch(`${API_BASEPATH}/api/artists/is-follow/${artistId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  return (await res.json()) as IsArtistFollowedRes
}

export const getArtistsCount = async () => {
  const query = qs.stringify(
    {
      fields: ['name'],
      pagination: { page: 1, pageSize: 0 },
    },
    { encodeValuesOnly: true }
  )
  const {
    meta: {
      pagination: { total: totalArtists },
    },
  } = await getArtists(query)

  return totalArtists
}
