import { debounce } from 'debounce'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useRef,
  useState,
} from 'react'

type State = {
  search: string
  debouncedSearch: string
  setSearch: (search: string) => void
  resetSearch: VoidFunction
}

export const MyAccountContext = createContext<State>({} as State)

export const MyAccountProvider = ({ children }: PropsWithChildren) => {
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')

  const setSearchDebounced = useRef(
    debounce((value: string) => {
      setDebouncedSearch(value)
    }, 500)
  )

  const setSearchHandler = useCallback((value: string) => {
    setSearch(value)
    setSearchDebounced.current(value)
  }, [])

  const resetSearch = useCallback(() => {
    setSearch('')
    setDebouncedSearch('')
  }, [])

  return (
    <MyAccountContext.Provider
      value={{
        search,
        debouncedSearch,
        setSearch: setSearchHandler,
        resetSearch,
      }}
    >
      {children}
    </MyAccountContext.Provider>
  )
}
