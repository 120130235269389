import classNames from 'classnames'
import { MouseEventHandler } from 'react'
import { ReactSVG } from 'react-svg'

type Props = {
  name: string
  size?: number
  filled?: boolean
  custom?: boolean
  className?: string
  transition?: string
  onClick?: MouseEventHandler
}

export const Icon = ({
  name,
  size,
  filled,
  custom,
  className,
  transition = 'svg-icon',
  onClick,
}: Props) => {
  return (
    <div
      style={{
        width: size ? `${size}px` : '1.2em',
        height: size ? `${size}px` : '1.2em',
      }}
      className={classNames(
        'flex items-center justify-center',
        className,
        transition
      )}
    >
      <ReactSVG
        src={`/files/icons/${filled ? 'filled/' : ''}${
          custom ? 'custom/' : ''
        }${name}.svg`}
        className='icon'
        // style={{
        //   width: size ? `${size}px` : '1.2em',
        //   height: size ? `${size}px` : '1.2em',
        // }}
        beforeInjection={svg => {
          svg.setAttribute(
            'style',
            `${
              size
                ? `width: ${size}px; height: ${size}px;`
                : 'width: 1.2em; height: 1.2em;'
            }`
          )

          const fill = svg.getAttribute('fill')
          fill && fill !== 'none' && svg.setAttribute('fill', 'currentColor')

          const stroke = svg.getAttribute('stroke')
          stroke &&
            fill !== 'none' &&
            svg.setAttribute('stroke', 'currentColor')

          if (custom) {
            return
          }

          const paths = svg.querySelectorAll('*')
          paths.forEach(path => {
            const newFill = path.getAttribute('fill')
            newFill &&
              newFill !== 'none' &&
              path.setAttribute('fill', 'currentColor')

            const newStroke = path.getAttribute('stroke')
            newStroke &&
              newFill !== 'none' &&
              path.setAttribute('stroke', 'currentColor')
          })
        }}
        onClick={onClick}
      />
    </div>
  )
}
