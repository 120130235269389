import { IAsk, IBid } from '@/types'
import { UserProfile } from 'api/profiles'
import { Fees, Order } from './data'

export type TradeFee = { name: string; value: number }

export const defaultFees: Fees & { processFeeNonGb: number } = {
  applicationFee: 6,
  dhlInsuranceFee: 1.5,
  processFee: 4,
  processFeeNonGb: 4,
  royalitiesFee: 3,
}

export const getFeesFromProfile = (
  userProfile: UserProfile | undefined
): Fees => {
  return {
    applicationFee:
      (userProfile?.applicationFee ?? defaultFees.applicationFee) / 100,
    dhlInsuranceFee:
      (userProfile?.dhlInsuranceFee ?? defaultFees.dhlInsuranceFee) / 100,
    processFee: (userProfile?.processFee ?? defaultFees.processFee) / 100,
    royalitiesFee:
      (userProfile?.royalitiesFee ?? defaultFees.royalitiesFee) / 100,
  }
}

export const getFeesFromOrder = (order: Order | undefined): Fees => {
  return {
    applicationFee: (order?.applicationFee ?? defaultFees.applicationFee) / 100,
    dhlInsuranceFee:
      (order?.dhlInsuranceFee ?? defaultFees.dhlInsuranceFee) / 100,
    processFee: (order?.processFee ?? defaultFees.processFee) / 100,
    royalitiesFee: (order?.royalitiesFee ?? defaultFees.royalitiesFee) / 100,
  }
}

export const getFeesFromAskAndBid = (
  ask?: IAsk['attributes'],
  bid?: IBid['attributes']
): Fees => {
  return {
    applicationFee: (ask?.applicationFee ?? defaultFees.applicationFee) / 100,
    dhlInsuranceFee:
      (ask?.dhlInsuranceFee ?? defaultFees.dhlInsuranceFee) / 100,
    processFee: (bid?.processFee ?? defaultFees.processFee) / 100,
    royalitiesFee: (ask?.royalitiesFee ?? defaultFees.royalitiesFee) / 100,
  }
}

export const getDhlFee = (price: number, dhlInsuranceFee: number) => {
  if (price <= 800) return 12

  return price * dhlInsuranceFee
}
