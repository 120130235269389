import {
  BulkAskEdition,
  createInitialAsk,
  deleteAsk,
  editAsk,
  getBulkAsks,
  placeBulkAsks,
  sellNow,
  voidBulkAsks,
} from 'api/asks'
import { AskPlacedModal } from 'components/common/AskPlacedModal'
import { Icon } from 'components/common/Icon'
import routes from 'constants/routes'
import { useAuth, useOverlay, useTradeAction } from 'contexts/hooks'
import dayjs from 'dayjs'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { PropsWithChildren, createContext, useCallback } from 'react'
import { useQuery } from 'react-query'
import { NamedEntity, SellingTradeType } from 'types'
import { Fees } from 'utils/data'
import { getDhlFee } from 'utils/fees'

export type BulkUploadArtwork = {
  id: number
  src: string
  artists: NamedEntity[]
  name: string
  editions: BulkAskEdition[]
  price: number
  currentEdition: BulkAskEdition
  askId: number
  documentStatus: string
  type: SellingTradeType
  insurance?: boolean
  platformFee?: number
  royaltyFee?: number
  insuranceFee?: number
  payout?: number
  expiresIn?: number
}

type AddListingPayload = {
  currentEdition: BulkAskEdition
  price: number
  expiresIn: number
  type: SellingTradeType
}
type AddListingFn = (payload: AddListingPayload) => void

type UpdateListingPayload = Partial<Parameters<typeof editAsk>['1']>
type UpdateListingFn = (askId: number, payload: UpdateListingPayload) => void

type DeleteListingFn = (askId: number) => void

type State = {
  artworks: BulkUploadArtwork[]
  addListing: AddListingFn
  updateListing: UpdateListingFn
  deleteListing: DeleteListingFn
  placeListings: VoidFunction
  voidListings: VoidFunction
}

export const BulkUploadContext = createContext<State>({} as State)

export const BulkUploadProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const { user } = useAuth()
  const { setConfig, setIsOpen } = useOverlay()
  const { t } = useTranslation('common')
  const { setIsLoading } = useTradeAction()

  const { data: artworks = [], refetch } = useQuery<BulkUploadArtwork[]>(
    'bulk-asks',
    async () => {
      const bulk = await getBulkAsks()

      if (!bulk?.length) return []

      return bulk
        .map<BulkUploadArtwork | undefined>(ask => {
          if (!ask.edition?.artwork) return

          const {
            id: askId,
            price: initialPrice = 0,
            edition,
            applicationFee = 0,
            royalitiesFee = 0,
            dhlInsuranceFee = 0,
            dhlInsurance,
            expiresAt,
            documentStatus = '',
          } = ask

          const { id: editionId, artwork } = edition

          const {
            id: artworkId = 0,
            artists = [],
            name = '',
            editions = [],
            images = [],
          } = artwork ?? {}

          const currentEdition = editions.find(e => e.id === editionId)

          if (!currentEdition) return

          const price = initialPrice / 100

          const artworkFees = getArtworkFees(
            { price, insurance: dhlInsurance },
            {
              applicationFee: applicationFee / 100,
              dhlInsuranceFee: dhlInsuranceFee / 100,
              processFee: 0,
              royalitiesFee: royalitiesFee / 100,
            }
          )

          let expiresIn = 0

          if (expiresAt) {
            const now = dayjs()
            const expiresAtDate = dayjs(expiresAt)

            const diff = expiresAtDate.diff(now, 'days')

            if (diff) {
              if (diff < 30) expiresIn = 30
              else if (diff < 60) expiresIn = 60
            }
          }

          return {
            artists,
            name,
            id: artworkId,
            price,
            src: images[0]?.url,
            editions,
            askId,
            insurance: dhlInsurance,
            currentEdition,
            documentStatus,
            ...artworkFees,
            type: getArtworkType({ currentEdition, price }),
            expiresIn,
          }
        })
        .filter(Boolean) as BulkUploadArtwork[]
    },
    { enabled: false, refetchOnWindowFocus: true }
  )

  const getArtworkType = useCallback(
    ({
      currentEdition,
      price,
    }: {
      currentEdition?: BulkAskEdition
      price: number
    }) => {
      let type: SellingTradeType = 'ask'

      if (currentEdition?.highestBid) {
        const { highestBid } = currentEdition

        if (highestBid.price && price <= highestBid.price / 100) {
          type = 'sell'
        }
      }

      return type
    },
    []
  )

  const getArtworkFees = useCallback(
    (
      { price, insurance }: { price: number; insurance?: boolean },
      fees: Fees
    ) => {
      const royaltyFee = price * fees.royalitiesFee
      const platformFee = price * fees.applicationFee
      const insuranceFee = getDhlFee(price, fees.dhlInsuranceFee)

      return {
        royaltyFee,
        platformFee,
        insuranceFee,
        payout:
          price - royaltyFee - platformFee - (insurance ? insuranceFee : 0),
      }
    },
    []
  )

  const addListing: AddListingFn = useCallback(
    async ({ currentEdition, price, expiresIn, type }: AddListingPayload) => {
      try {
        if (!currentEdition) return

        let error: Error | undefined

        if (type === 'ask') {
          const res = await createInitialAsk(
            currentEdition.id,
            price,
            expiresIn || 0,
            true
          )

          error = res.error
        }

        if (type === 'sell') {
          const res = await sellNow(currentEdition.id, true)

          error = res.error
        }

        if (error) throw new Error(error.message)
        refetch()
      } catch (err) {
        console.error(err)
      }
    },
    [refetch]
  )

  const updateListing: UpdateListingFn = useCallback(
    async (
      askId: number,
      {
        price,
        editionId,
        expiresInDays = 0,
        dhlInsurance,
      }: UpdateListingPayload
    ) => {
      try {
        const { error } = await editAsk(askId, {
          expiresInDays,
          dhlInsurance,
          price,
          editionId,
        })

        if (error) throw new Error(error.message)

        refetch()
      } catch (err) {
        console.error(err)
      }
    },
    [refetch]
  )

  const deleteListing: DeleteListingFn = useCallback(
    async (askId: number) => {
      try {
        const { error } = await deleteAsk(askId)

        if (error) throw new Error(error.message)

        refetch()
      } catch (err) {
        console.error(err)
      }
    },
    [refetch]
  )

  const placeListings = useCallback(async () => {
    if (!user) return

    setIsLoading(true)

    try {
      await placeBulkAsks(
        artworks.map(a => ({
          id: String(a.askId),
          documentStatus: a.documentStatus,
          price: a.price,
          type: a.type,
          userId: String(user?.id),
          highestBidId: String(a.currentEdition.highestBid?.id),
        }))
      )

      await router.push(routes.myAccount.selling)

      setConfig({
        className: 'flex items-center',
        body: (
          <AskPlacedModal
            askStatus='Approved'
            title={
              <>
                <Icon
                  name='filled-success'
                  filled
                  size={24}
                  className='mr-2 text-success-default'
                />
                {t('your_listings_are_live')}
              </>
            }
          />
        ),
      })
      setIsOpen(true)
      setIsLoading(false)
    } catch (err) {
      console.error(err)
      setIsLoading(false)
    }
  }, [user, artworks, router])

  const voidListings = useCallback(async () => {
    try {
      await voidBulkAsks()
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <BulkUploadContext.Provider
      value={{
        artworks,
        addListing,
        updateListing,
        deleteListing,
        placeListings,
        voidListings,
      }}
    >
      {children}
    </BulkUploadContext.Provider>
  )
}
