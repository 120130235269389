import { useRouter } from 'next/router'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useOverlay } from '../hooks/'

export const RELEASE_DATE = new Date(Date.UTC(2022, 11, 6, 13))

type State = {
  hasAccess: boolean | undefined
  setHasAccess: Dispatch<SetStateAction<boolean | undefined>>
}

type Props = {
  children: ReactNode
}

const { NEXT_PUBLIC_ACCESS_PASS } = process.env

export const AccessContext = createContext<State>({} as State)

export const AccessProvider = ({ children }: Props) => {
  const releasePassed = RELEASE_DATE.getTime() - new Date().getTime() < 0
  const [hasAccess, setHasAccess] = useState<boolean | undefined>(releasePassed)
  const accessIsDefined = typeof hasAccess !== 'undefined'

  const router = useRouter()
  const { setConfig, setIsOpen } = useOverlay()

  const intercept = () => {
    if (!accessIsDefined || hasAccess) return

    router.events.emit('routeChangeError')
    throw 'You don`t have access to use the website'
  }

  useEffect(() => {
    if (!NEXT_PUBLIC_ACCESS_PASS) {
      setHasAccess(true)
      return
    }

    const accessPass = localStorage.getItem('access_pass')
    setHasAccess(accessPass === NEXT_PUBLIC_ACCESS_PASS)
  }, [])

  useEffect(() => {
    if (
      releasePassed ||
      !accessIsDefined ||
      hasAccess ||
      !NEXT_PUBLIC_ACCESS_PASS
    )
      return

    router.push('/').then(() => {
      setConfig({
        body: null,
        closeable: false,
        className: 'px-6 items-center overflow-y-auto',
      })
      setIsOpen(true)

      router.events.on('routeChangeStart', intercept)
    })

    return () => {
      router.events.off('routeChangeStart', intercept)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAccess])

  return (
    <AccessContext.Provider value={{ hasAccess, setHasAccess }}>
      {children}
    </AccessContext.Provider>
  )
}
