export const diacritics = {
  a: ['à', 'á', 'â', 'ã', 'ä', 'å', 'æ'],
  c: ['ç'],
  e: ['è', 'é', 'ê', 'ë', 'ð'],
  i: ['ì', 'í', 'î', 'ï'],
  n: ['ñ'],
  o: ['ò', 'ó', 'ô', 'õ', 'ö', 'ø'],
  u: ['ù', 'ú', 'û', 'ü'],
  y: ['ý', 'ÿ'],
  p: ['þ'],
  s: ['ß'],
}
