import classNames from 'classnames'
import { StaticIcon } from 'components/common/StaticIcon'

const themes = {
  white: 'text-cool-50',
  gray: 'text-cool-800',
  purple: 'text-primary-default',
} as const

const sizes = {
  md: {
    outsideSize: 12,
    insideSize: 24,
    className: 'h-6 w-6',
  },
  sm: {
    outsideSize: 8,
    insideSize: 16,
    className: 'h-4 w-4',
  },
  xs: {
    outsideSize: 6,
    insideSize: 12,
    className: 'h-3 w-3',
  },
} as const

type Props = {
  className?: string
  theme?: keyof typeof themes
  size?: keyof typeof sizes
}

export const Spinner = ({ className, theme = 'gray', size = 'md' }: Props) => {
  return (
    <div className={`${className}`}>
      <div
        className={classNames('relativ animate-spin', sizes[size].className)}
      >
        <StaticIcon
          name='spinnerOutside'
          size={sizes[size].outsideSize}
          className={`absolute left-1/2 ${themes[theme]}`}
        />
        <StaticIcon
          name='spinnerInside'
          size={sizes[size].insideSize}
          className={`absolute ${themes[theme]}`}
        />
      </div>
    </div>
  )
}
